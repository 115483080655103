@import "../../../styles/_variables.scss";
$border_color: #202020;

.input-form-control {
    position: relative;

    &__label {
        font-size: 14px;
        color: $secondary_text;
        font-weight: 700;
        text-transform: uppercase;

        @media screen and (min-width: 744px) {
            font-size: 18px;
        }
    }

    &__wrapper-input {
        width: 100%;
        position: relative;
        padding-bottom: 30px;
        margin-top: 20px;

        &.error {
            & .input-form-control__input {
                border-color: red;
                transition: border-color 300ms ease;
            }

            & .input-form-control__btn:hover {
                border-color: red;
            }
        }
    }

    &-adornment {
        position: absolute;
        z-index: 6;
        top: 0;
        min-width: 49px;
        min-height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__end {
            right: 0px;
        }
    }

    &__input {
        height: 50px;
        width: 100%;
        border: 2px solid #999999;
        border-radius: 20px;
        font-size: 16px;
        line-height: 29px;
        color: rgba(255, 255, 255, 0.52);
        background-color: #fff;
        color: #202020;
        padding-right: 20px;
        padding-left: 20px;
        transition: border-color 300ms ease;
        font-family: inherit;

        &::placeholder {
            color: #D0D0D0;
        }

        &:focus,
        &:focus-visible {
            outline: none;
        }

        @media screen and (min-width: 744px) {
            font-size: 18px;
        }

        @media screen and (min-width: 996px) {
            height: 67px;
            font-size: 24px;
        }
    }

    &__error {
        font-size: 13px;
        color: red;
        position: absolute;
        width: 100%;
        bottom: 6px;
        left: 0;
        transition: all 300ms ease;
        text-align: right;
        visibility: hidden;
        opacity: 0;
        z-index: -1;

        &.error {
            opacity: 1;
            visibility: visible;
            z-index: 1;
            transition: all 300ms ease;
        }
    }
}