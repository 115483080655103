@import "../../styles/variables";

.infoWrapper {
    position: relative;
}

.info {
    padding-top: 0px;
    margin-top: 46px;

    &Subtitle {
        font-weight: 400;
        color: $desc;
        padding-bottom: 13px;
        font-size: 16px;
        position: relative;
        display: inline-block;

        @media screen and (min-width: 744px) {
            @include adaptive-var("font-size", 23, 14, 1);
        }
    }

    @media screen and (min-width: 744px) {
        padding-bottom: 50px;
        margin-top: 160px;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 13vmax;
        padding-bottom: 19vmax;
    }

    @media screen and (min-width: 1919px) {
        margin-top: 250px;
        padding-bottom: 394px;
    }

    &Icon {
        display: none;

        @media screen and (min-width: 996px) {
            display: block;
        }
    }
}

.title {
    padding-top: 15px;
    padding-bottom: 20px;
    max-width: 953px;
    width: 100%;


    @media screen and (min-width: 996px) {
        padding-bottom: 70px;
        width: 68%;
        padding-right: 98px;
    }

    &Ru {
        &[data-lang='ru'] {
            font-size: 40px;

            @media screen and (min-width: 744px) {
                font-size: 56px;
            }

            @media screen and (min-width: 996px) {
                @include adaptive-var("font-size", 78, 1, 1);
            }
        }
    }
}

.previewContainer {
    display: flex;
    justify-content: center;
}

.preview {
    height: 288px;
    width: 288px;

    @media screen and (min-width: 744px) {
        height: 644px;
        width: 644px;
    }

    @media screen and (min-width: 996px) {
        max-width: 636px;
        width: 33vw;
        position: absolute;
        top: 0px;
        transform: translateY(-7vmax);
        right: 82px;
        max-height: 1070px;
        z-index: -1;
        display: block;
        height: auto;
    }
}