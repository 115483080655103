@import "../../styles/_variables.scss";

.grid {
    display: flex;

    &_content_center {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__row {
        flex-direction: column;

        @media (min-width: 996px) {
            flex-direction: row;
        }
    }

    &__column {
        flex-direction: column;
    }

    &__align_center {
        align-items: center;
    }

    &__align_start {
        align-items: flex-start;
    }

    &__align_end {
        align-items: flex-end;
    }

    &__right {
        &.grid_default {
            width: 100%;
        }

        &.grid_equal {
            width: 100%;

            @media (min-width: 996px) {
                width: 50%;
            }

        }

        &.grid_padding.grid_row {

            @media (min-width: 996px) {
                padding-left: 20px;
            }

            @media (min-width: 1280px) {
                @include adaptive-var("padding-left", 65, 15, 1);
            }
        }
    }

    &__left {
        &.grid_default {
            width: 100%;

            &.grid_row {
                padding-right: 20px;

                @media (min-width: 996px) {
                    width: calc(70% + 20px);
                }
            }

        }

        &.grid_equal {
            width: 100%;
            order: 2;
            margin-top: 32px;

            @media (min-width: 996px) {
                width: 50%;
                order: 0;
                margin-top: 0px;
            }

            @media (min-width: 1280px) {
                width: 50%;
            }
        }

        &.grid_padding.grid_row {
            @media (min-width: 996px) {
                padding-right: 20px;
            }

            @media (min-width: 1280px) {
                @include adaptive-var("padding-right", 65, 15, 1);
            }
        }

    }

    &_order {
        order: 0;

        @media (min-width: 996px) {
            order: 1;
            padding-left: 20px;
            padding-right: 0px;
        }

    }
}