.notification-bar{
    position: fixed;
    bottom: 30px;
    transform: translateX(-50%);
    left: 50%;
    padding: 28px;
    background-color: black;
    color: #fff;
    z-index: 1000;
    min-width: 300px;
    max-width: 576px;
    min-height: 30px;
    width: auto;
    border-radius: 10px;
    font-size: 14px;
    transition: all 300ms ease;
    &-enter {
        opacity: 0;
        transform: scale(0.9);
    }
    &-enter-active {
        opacity: 1;
        transform: translateX(-50%);
        transition: opacity 300ms, transform 300ms;
    }
    &-exit {
        opacity: 1;
    }
    &-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
    }
    &.success{
        background-color: rgb(237, 247, 237);
        color: rgb(30, 70, 32);
        & svg{
            color: #4caf50;
        }
    }
    &.info{
        color: rgb(1, 67, 97);
        background-color: rgb(229, 246, 253);
        & svg{
            color: #03a9f4;
        }
    }
    &.warning{
        color: rgb(102, 60, 0);
        background-color: rgb(255, 244, 229);
        & svg{
            color: #ff9800;
        }
    }
    &.error{
        background-color: rgb(253, 237, 237);
        color: rgb(95, 33, 32);
        & svg{
            color: #ef5350;
        }
    }
    &__content{
        display: flex;
        align-items: flex-start;
    }
    &__icon{
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }
    &__msg{
        width: calc(100% - 32px);
        flex-grow: 1;
        padding-top: 2px;
    }
    &__button{
        width: 1em;
        height: 1em;
        background: transparent;
        border: none;
        transform: scale(1);
        transition: transform 300ms ease;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 6px;
        &:hover{
            transform: scale(0.9);
            transition: transform 300ms ease; 
        }
    }
    & svg{
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentColor;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.25rem;
    }
}