.form {
    max-width: 700px;
    margin: 0 auto;
}

.title {
    text-align: center;
    padding: 40px 0px;
}

.massege {
    min-height: 50px;

    @media screen and (min-width: 996px) {
        min-height: 150px;
    }
}

.fromActions {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 44px;
}

.formActionSend {
    min-width: 178px;
    position: relative;
}

.formActionSendTitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.formActionSendLoader {
    position: absolute;
    right: 4px;
    width: 30px;
    height: 30px;
}

.brief {
    padding-bottom: 10px;
    overflow: hidden;
}

.pb {
    padding-bottom: 12px;
}

.flexCenter {
    display: flex;
    align-items: center;
}