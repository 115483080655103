@import "../../../styles/_variables.scss";

.card {
    text-align: center;
    max-width: 296px;
    min-height: 165px;
    min-width: 165px;
    width: auto;
    height: auto;
}

.preview {
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    max-height: 292px;
    max-width: 292px;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    border: 8px solid rgba(255, 198, 61, 1);

    @media (min-width: 1200px) {
        margin-bottom: 30px;
    }
}

.name {
    color: #383838;
    font-size: 24px;
    line-height: 1;
    font-weight: 700;
    padding-bottom: 10px;
    word-wrap: break-word;

    &[data-lang='ru'] {
        font-size: 18px
    }

    @media (min-width: 1200px) {
        font-size: 28px
    }

    @media (min-width: 1520px) {
        @include adaptive-var("font-size", 40, 12, 1);
        padding-bottom: 20px;

        &[data-lang='ru'] {
            @include adaptive-var("font-size", 32, 12, 1);
        }
    }

}

.about {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $desc;

    @media (min-width: 744px) {
        font-size: 12px;
    }

    @media (min-width: 996px) {
        font-size: 16px
    }

    @media (min-width: 1520px) {
        @include adaptive-var("font-size", 18, 12, 1);

        &[data-lang='ru'] {
            @include adaptive-var("font-size", 16, 12, 1);
        }
    }

}

.position {
    font-weight: 700;
    font-size: 14px;
    line-height: 29px;
    color: $secondary_text;
    padding-bottom: 5px;

    @media (min-width: 744px) {
        font-size: 18px;
        padding-bottom: 10px;

        &[data-lang='ru'] {
            font-size: 14px;
        }
    }

    @media (min-width: 1200px) {
        @include adaptive-var("font-size", 24, 12, 1);

        &[data-lang='ru'] {
            @include adaptive-var("font-size", 18, 12, 1);
        }
    }
}