@import "../../../styles/_variables.scss";

.container {
    padding: 12px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $border;
    border-radius: 40px;
    cursor: pointer;

    &:hover {
        border: 2px solid $secondary_text;
    }
}

.containerGradient {
    position: relative;
    border: 2px solid transparent;
    background-color: #fff;
    z-index: 3;

    &::after {
        content: "";
        background: linear-gradient(to left, rgba(255, 198, 61, 1), rgba(255, 243, 142, 1));
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 9px);
        height: calc(100% + 9px);
        z-index: -1;
        border-radius: 300px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms ease, visibility 300ms ease;

        @media (min-width: 1200px) {
            width: calc(100% + 10px);
            height: calc(100% + 10px);
        }
    }
    @media (min-width: 1200px) {
        &:hover {
            border: 2px solid transparent;
    
            .titleGradient {
                opacity: 1;
                transition: all 300ms ease;
            }
    
            &::after {
                content: "";
                opacity: 1;
                visibility: visible;
                transition: opacity 300ms ease, visibility 300ms ease;
            }
        }
    }
    
}

.border {
    background: linear-gradient(to left, rgba(255, 198, 61, 1), rgba(255, 243, 142, 1));
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 9px);
    height: calc(100% + 9px);
    z-index: -1;
    opacity: 1;
    border-radius: 300px;
    transition: opacity 300ms ease;

    @media (min-width: 1200px) {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
    }
}

.borderContent {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);
    width: calc(100% + 3.5px);
    height: calc(100% + 3.5px);
    border-radius: 300px;
    z-index: -1;
    opacity: 1;
    transition: opacity 300ms ease;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1200px) {
        transform: translate(-50%, -50%);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
    }
}

.borderContentBig {
    transform: translate(-50.5%, -52%);
    width: calc(100% + 3.5px);
    height: calc(100% + 4px);

    @media (min-width: 1200px) {
        transform: translate(-50%, -50%);
    }
}

.title {
    font-weight: 500;
    font-size: 14px;
    color: $primary_text;
    text-transform: uppercase;

    @media screen and (min-width: 744px) {
        font-size: 18px;
    }
}

.content {
    text-align: center;
}

.titleGradient {
    opacity: 0;
    transition: all 300ms ease;
}