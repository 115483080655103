.scroll {
    width: 42px;
    height: 42px;
    color: #E4E4E4;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 744px) {
        width: 84px;
        height: 84px;
    }
}

.img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.arrow {
    width: 10px;
    height: 10px;

    @media screen and (min-width: 744px) {
        width: 20px;
        height: 20px;
    }
}