@import "../../../styles/_variables.scss";

.section {
    padding-top: 25px;
    padding-bottom: 25px;

    @media screen and (min-width: 996px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.box {
    width: 2px;
    height: 10vmax;
}

.title {
    font-weight: 800;
    font-size: 40px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on;
    color: $primary_text;
    white-space: nowrap;

    span {
        color: $secondary_text;
    }

    @media (min-width: 744px) {
        font-size: 80px;

        &[data-lang='ru'] {
            font-size: 70px
        }
    }

    @media (min-width: 1200px) {
        @include adaptive-var("font-size", 76, 2, 1);

        &[data-lang='ru'] {
            @include adaptive-var("font-size", 62, 2, 1);
        }
    }

    @media (min-width: 1700px) {
        @include adaptive-var("font-size", 94, 2, 1);

        &[data-lang='ru'] {
            @include adaptive-var("font-size", 84, 2, 1);
        }
    }

}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    color: $primary_text;
    padding-top: 20px;

    @media (min-width: 744px) {
        font-size: 18px;
    }

    @media (min-width: 1200px) {
        padding-top: 0px;
        font-size: 24px;
        line-height: 33px;
    }
}

.button {
    padding: 10px 30px;
    border-radius: 300px;
    margin-top: 60px;

    span {
        font-size: 18px;
    }

    @media (min-width: 744px) {
        span {
            font-size: 32px;
        }
    }

    @media (min-width: 1200px) {
        span {
            font-size: 22px;
        }
    }

    @media (min-width: 1530px) {
        span {
            font-size: 32px;
        }
    }
}

.preview {

    object-fit: cover;
    object-position: center;
    width: 290px;
    height: 185px;
    max-width: 644px;
    max-height: 408px;

    @media screen and (min-width: 540px) {
        width: 100%;
        height: auto;
    }

    @media screen and (min-width: 1200px) {
        width: 47vmax;
        max-width: 919px;
        max-height: 590px;
    }
}

.orderTop {
    @media screen and (max-width: 996px) {
        order: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
}

.titlePadding {
    padding-bottom: 20px;

    @media screen and (min-width: 996px) {
        padding-bottom: 40px;
    }
}