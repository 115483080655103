@import "../../styles/_variables.scss";

.header {
    padding: 28px var(--bs-gutter-x, 0.9375rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;

    @media screen and (min-width: 996px) {
        padding: 40px var(--bs-gutter-x, 0.9375rem);
        height: 94px;
    }

    @media screen and (min-width: 1520px) {
        height: 130px;
    }

    &_fixed {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        transition: ease 0.4s;
        background: $background;
        z-index: $zIndexHeader;
        box-shadow: 0px 0px 25px rgba(56, 56, 56, 0.1);
    }
}

.logo {
    max-width: 150px;
    max-height: 30px;
    width: 100px;
    height: 20px;
    margin-right: 30px;
    z-index: 210;

    @media screen and (min-width: 996px) {
        height: 30px;
        width: 150px;
    }

    @media screen and (min-width: 1520px) {
        margin-right: 171px;
    }
}

.menu {
    display: none;

    @media screen and (min-width: 1200px) {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}

.navigation {
    display: flex;
    flex-grow: 1;
    padding-right: 6px;
    align-items: center;

    @media screen and (min-width: 1200px) {
        padding-right: 77px;
    }
}

.actions {
    display: flex;
    align-items: center;
}

.box {
    padding: 17px;
}

.actionMenu {
    margin-left: 20px;

    @media screen and (min-width: 1200px) {
        display: none;
    }
}

.actionsLeft {
    display: none;

    @media screen and (min-width: 576px) {
        display: flex;
        align-items: center;
    }
}

.actionLang {
    display: none;

    @media screen and (min-width: 996px) {
        display: block;
    }
}