@import "../../../styles/_variables.scss";

.card-achievement__paper {}

.card-achievement {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
    margin-bottom: 20px;

    @media screen and (min-width: 744px) {
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    &-body {
        margin-left: 10px;

        @media screen and (min-width: 744px) {
            margin-left: 0px;
        }
    }

    &__paper {
        padding: 10px;
        margin-top: 6px;

        @media screen and (min-width: 744px) {
            margin-top: 0px;
        }

        .paper__content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 10px;

        @media screen and (min-width: 744px) {
            font-size: 32px;
            padding-bottom: 20px;
        }

        @media screen and (min-width: 1520px) {
            font-size: 40px;
        }
    }

    &__desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        color: #505255;
        line-height: 19px;

        @media screen and (min-width: 744px) {
            font-size: 16px;
            padding-bottom: 20px;
            line-height: 22px;
        }

        @media screen and (min-width: 1520px) {
            font-size: 18px;
            line-height: 25px;
        }

    }

    &__icon {
        width: 80px;
        height: 80px;
    }

}