@import "../../../styles/_variables.scss";

.hamburger-lines {
    cursor: pointer;
    display: block;
    height: 25px;
    width: 32px;
    z-index: 1002;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .hamburger-line {
        display: block;
        height: 4px;
        width: 100%;
        background: $black;
        transition: background-color 0.4s ease-in-out;
    }

    .hamburger-line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-line2 {
        transition: transform 0.2s ease-in-out;
    }

    .hamburger-line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    &.active {
        .hamburger-line {
            background: $black;
            transition: all 0.4s ease-in-out;
        }

        .hamburger-line1 {
            transform: rotate(45deg);
        }

        .hamburger-line3 {
            transform: rotate(-45deg);
        }

        .hamburger-line2 {
            transform: scaleY(0);
        }
    }

}