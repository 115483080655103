@import "../../styles/variables.scss";

.banner {
    min-height: 355px;
    height: 100%;
    width: 100%;
    position: relative;

    @media (min-width: 744px) {
        height: 838px;
    }

    @media (min-width: 1200px) {
        height: 43vmax;
        max-height: 826px;
    }

    &_default {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &_overlay::before {
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 1;
    }

    &_fixed {
        background-attachment: fixed;
    }

    &__body {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &_justify-center {
            justify-content: center;
        }

        &_justify-end {
            justify-content: flex-end;
        }

        &_justify-start {
            justify-content: flex-start;
        }
    }

    &__title {
        text-align: center;
        padding-bottom: 20px;

        @media (min-width: 1200px) {
            padding-top: 20px;
            @include adaptive-var("font-size", 70, 12, 1);
        }

        @media (min-width: 1530px) {
            @include adaptive-var("font-size", 96, 32, 1);
        }
    }

    &__desc {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 14px;
        line-height: 100%;
        max-width: 290px;
        margin: 0 auto;

        @media (min-width: 744px) {
            font-size: 32px;
        }

        @media (min-width: 996px) {
            font-size: 40px;
            max-width: max-content;
        }

        @media (min-width: 1200px) {
            @include adaptive-var("font-size", 30, 14, 1);
        }

        @media (min-width: 1530px) {
            @include adaptive-var("font-size", 40, 14, 1);
        }


    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__quote {
        margin: 0 auto;
        padding: 10px 30px;
        border: 1px solid rgba(56, 56, 56, 1);
        border-radius: 30px;
        display: inline-block;
        font-size: 16px;
        text-align: center;
        max-width: 240px;
        margin-top: 16px;

        @media (min-width: 744px) {
            font-size: 24px;
            max-width: max-content;
        }

        @media (min-width: 744px) {
            @include adaptive-var("font-size", 24, 12, 1);
        }

        @media (min-width: 1200px) {
            @include adaptive-var("font-size", 18, 12, 1);
        }

        @media (min-width: 1530px) {
            @include adaptive-var("font-size", 24, 12, 1);
        }

    }
}