@import "../../styles/variables";

.menu {
    padding: 2px 0px;
    width: 100%;
}

.menuList {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    &Item {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $primary_text;
        position: relative;
        padding: 25px 14px;
        z-index: 1;
        transition: color 300ms ease;
        border-radius: 5px;

        &::before {
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            left: 0px;
            top: 0px;
            background-color: $secondary_text;
            z-index: -1;
            opacity: 0;
            transition: width 400ms ease, opacity 400ms ease;
            border-radius: 5px;
        }

        &:hover {
            color: $write;
            transition: color 300ms ease;

            &::before {
                width: 100%;
                opacity: 1;
                transition: width 400ms ease, opacity 400ms ease;
            }
        }

        @media screen and (min-width: 1200px) {
            padding: 0px 14px;
            margin: 0px 14px;
        }

        @media screen and (min-width:1520px) {
            margin: 0px 45px;
            font-size: 18px;
        }
    }

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        align-items: center;
    }
}

.menuListCustom {
    display: flex;
    justify-content: space-between;

    &Item {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $primary_text;
        position: relative;
        padding: 25px 14px;
        z-index: 1;
        transition: color 300ms ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            left: 0px;
            top: 0px;
            background-color: $secondary_text;
            z-index: -1;
            opacity: 0;
            transition: width 400ms ease, opacity 400ms ease;
            border-radius: 5px;
        }

        @media screen and (min-width: 996px) {
            &:hover {
                color: $write;
                transition: color 300ms ease;

                &::before {
                    width: 100%;
                    opacity: 1;
                    transition: width 400ms ease, opacity 400ms ease;
                }
            }
        }

        @media screen and (min-width: 996px) {
            padding: 0px 14px;
        }
    }

}