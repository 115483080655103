@import "../../../styles/_variables.scss";

.card-skills__paper {
    width: 100%;
    height: 100%;
    padding: 10px 10px;

    @media screen and (min-width: 1530px) {
        padding: 50px 30px;
    }
}

.card-skills {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    &__title {
        font-size: 50px;
        letter-spacing: -0.05em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on;
        font-weight: 800;
        color: $write;

        @media screen and (min-width: 1200px) {
            @include adaptive-var("font-size", 96, 2, 1);
        }
    }

    &__desc {
        color: $black;
        font-weight: 700;
        font-size: 18px;
        text-align: center;

        @media screen and (min-width: 1200px) {
            @include adaptive-var("font-size", 20, 2, 1);
        }
    }


}

.card-skills_yellow {
    .card-skills__desc {
        color: $black;
    }
}

.card-skills_gray {
    .card-skills__desc {
        color: $secondary_text;
    }
}