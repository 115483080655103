.section {
    padding-top: 25px;
    padding-bottom: 25px;

    @media screen and (min-width: 996px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.slider {
    margin-right: -15px;

    @media screen and (min-width: 744px) {
        margin-right: 0px;
    }
}

.slide {
    max-width: 240px;

    @media screen and (min-width: 744px) {
        max-width: unset;
    }
}