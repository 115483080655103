@import "../../../styles/_variables.scss";

.info-section {
    &__title {

        font-size: 20px;
        color: #FFA041;
        padding-bottom: 20px;
        font-weight: 700;

        @media (min-width: 744px) {
            padding-bottom: 30px;
            font-size: 24px;
        }

        @media (min-width: 996px) {
            font-size: 28px;
            font-weight: 500;
        }

        @media (min-width: 1432px) {
            font-size: 32px;
        }
    }

    &__desc,
    &__render {
        font-weight: 400;
        font-size: 16px;

        @media (min-width: 744px) {
            font-size: 20px;
        }

        @media (min-width: 996px) {
            font-size: 16px;
        }
        // @media (min-width:1700px) {
        //     font-size: 24px;
        // }
        @media (min-width:1860px) {
            font-size: 24px;
        }

    }

    &__render {
        padding-bottom: 40px;

        p {
            padding-top: 40px;

            &:first-child {
                padding-top: 0px;
            }
        }

        ul {
            list-style: disc;
            flex-direction: column;
            padding-left: 26px;
        }
    }
}