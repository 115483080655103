@import "../../styles/_variables.scss";

.slideItem {
    max-height: 700px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.wrap {
    height: 36.45vmax;
    max-height: 700px;
    position: relative;
    min-height: 360px;

    @media screen and (min-width: 744px) {
        min-height: 402px;
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

    @media screen and (min-width: 996px) {
        padding-top: 40px;
    }
}

.counterContainer {
    display: flex;
    align-items: center;
}

.counter {
    font-weight: 400;
    font-size: 16px;
    line-height: 39px;
    color: $desc;
    margin-right: 2px;

    @media screen and (min-width: 996px) {
        font-size: 32px;
        margin-right: 8px;
    }
}

.counterActive {
    color: rgb(79, 82, 85);
}

.actions {
    display: flex;
    align-items: center;
}

.navigate {
    &Box {
        padding-left: 0px;

        @media screen and (min-width: 744px) {
            padding-left: 90px;
        }

        @media screen and (min-width: 996px) {
            padding-left: 112px;
        }
    }

    &Arrow {
        cursor: pointer;
        color: $black;
        transition: color 300ms ease;
        border: 2px solid;
        border-radius: 40px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        border: none;

        &::before {
            content: "";
            background: linear-gradient(to left, rgba(255, 198, 61, 1), rgba(255, 243, 142, 1));
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-radius: 40px;
            opacity: 0;
            top: 0;
            left: 0;
            visibility: hidden;
            transition: opacity 300ms ease, visibility 300ms ease;
        }

        @media screen and (min-width: 996px) {
            &:hover::before {
                opacity: 1;
                visibility: visible;
                transition: opacity 300ms ease, visibility 300ms ease;
            }
        }

        &Disable {
            pointer-events: none;
            color: $gray;
        }
    }

    display: flex;
    align-items: center;
}