@import "../../../styles/_variables.scss";

.card-competencies__paper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 743px) {
        padding: 20px 15px 15px 15px;
    }

    @media screen and (min-width: 744px) {
        min-height: 410px;
    }

    @media screen and (min-width:1200px) {
        min-height: 358px;

        &:hover {
            .card-competencies__icon {
                transition: color 400ms ease;
                color: $secondary_text;
            }

            .card-competencies__info {
                transition: transform 400ms ease-in-out;
                transform: translateY(0px) !important;
            }

            .card-competencies__title {
                color: $black;
                transition: color 400ms ease-in-out;
            }
        }
    }

    @media screen and (min-width: 1520px) {
        min-height: 410px;
    }

    &.active {
        background-color: $write;
        box-shadow: 0px 0px 25px rgba(56, 56, 56, 0.1);

        .card-competencies__icon {
            transition: color 400ms ease;
            color: $secondary_text;
        }

        .card-competencies__title {
            color: $black;
            transition: color 400ms ease-in-out;
        }

        .card-competencies__desc {
            color: $black;
            transition: color 400ms ease-in-out;
        }

    }

}

.card-competencies {
    &__icon {
        margin-bottom: 20px;
        width: 25px;
        height: 25px;
        transition: color 400ms ease;

        @media screen and (min-width: 1530px) {
            width: 50px;
            height: 50px;
        }

        &-gray {
            color: $black;
        }

        &-yellow {
            color: rgba(255, 198, 61, 1);
        }
    }

    &__info {

        @media screen and (min-width:1200px) {
            min-height: 244px;
            position: absolute;
            top: 60px;
            left: 0;
            transform: translateY(calc(100% - 38px));
            transition: transform 400ms ease-in-out;

            &[data-lang='ru'] {
                transform: translateY(calc(100% - 38px));
            }
        }

        @media screen and (min-width:1530px) {
            transform: translateY(calc(100% - 14px));
            top: 72px;

            &[data-lang='ru'] {
                transform: translateY(calc(100% - 14px));
            }
        }


    }

    &-list {
        padding-left: 19px;

        &__item {
            font-size: 16px;
            list-style: disc;

            @media (min-width: 1200px) {
                font-size: 14px;
            }

            @media (min-width: 1520px) {
                font-size: 16px;
            }
        }
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on;
        color: $write;
        transition: color 400ms ease-in-out;
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        @media screen and (min-width: 744px) {
            font-size: 32px;
            padding-bottom: 0px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 28px;
        }

        @media screen and (min-width: 1530px) {
            font-size: 40px;
        }

        & .card-competencies__icon {
            margin-right: 12px;
            margin-bottom: 0px;
        }
    }

    &__desc {
        margin-top: 20px;
        border-top: 1px solid $secondary_text;
        padding-top: 20px;
        font-weight: 400;
        color: $black;
        font-size: 16px;
        line-height: 22px;

        @media screen and (min-width: 1530px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.card-competencies_yellow {
    .card-competencies__title {
        color: $black;
    }
}

.card-competencies_gray {
    .card-competencies__title {
        color: $write;
    }

    .card-competencies__desc {
        color: $write;

        @media screen and (min-width: 1200px) {
            color: $black;
        }
    }

    .card-competencies-list__item {
        @media screen and (min-width: 744px) {
            color: $write;
        }

        @media screen and (min-width: 1200px) {
            color: $black;
        }
    }
}