@import "./functions";

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  ) !default;

$gutters: $spacers  !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 744px,
  lg: 996px,
  xl: 1200px,
  xxl: 1520px,
  xxxl: 2561px) !default;
//xxl:  1400px
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints"
);
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 600px,
  md: 674px,
  lg: 960px,
  xl: 1140px,
  xxl: 1436px,
  xxxl: 1920px) !default;
//xxl: 1320px
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths"
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.875rem !default;
$grid-row-columns: 6 !default;

// Container padding

$container-padding-x: $grid-gutter-width * .5 !default;