@import "../../../styles/_variables.scss";

.paper {
    padding: 50px 30px;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0px 0px 25px rgba(56, 56, 56, 0);

    &_hover {
        @media screen and (min-width: 1200px) {
            &:hover {
                box-shadow: 0px 0px 25px rgba(56, 56, 56, 0.1);
                transition: box-shadow 0.3s ease-in-out;

                &.paper_yellow::before {
                    transition: opacity 0.5s linear;
                    opacity: 1;
                }

                &.paper_gray {
                    transition: background-color 0.5s ease-out;
                    background-color: $write;
                }
            }

        }

    }

    &_yellow {
        background-image: $gradient;
        position: relative;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: $gradient_hover;
            z-index: 0;
            transition: opacity 0.5s linear;
            opacity: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        &.active {
            &::before {
                transition: opacity 0.5s linear;
                opacity: 1;
            }
        }
    }

    &_gray {
        background-color: $black;
        transition: background-color 0.5s ease-out;
    }

    &__content {
        position: relative;
        z-index: 2;
        height: 100%;
    }
}