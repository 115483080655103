@import "../../styles/_variables.scss";

.body {
    padding-top: 30px;
    overflow: hidden;
    position: relative;

    @media (min-width: 996px) {
        padding-top: 70px;
        &.body_padding{
            padding-top: 50px;
        }
    }
}

.bodyBase {
    padding-top: 30px;
    position: relative;

    @media (min-width: 996px) {
        padding-top: 70px;
    }
}

.cruella {

    &BoxFooter {
        padding-top: 20px;

        @media (min-width: 1200px) {
            padding-top: 105px;
        }
    }

    &OrderColumnLeft {
        @media (min-width: 1540px) {
            padding-left: 93px;
        }
    }

    &OrderColumnRight {
        order: 3;
        margin-top: 20px;

        @media (min-width: 996px) {
            order: 2;
            margin-top: 0px;
        }

    }

    &Img17 {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        aspect-ratio: 1/1;
    }

    &Img4 {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            position: absolute;
            right: 0px;
            bottom: -77px;
            max-width: 575px;
            max-height: 530px;
            object-fit: contain;
            object-position: center;
            z-index: -1;
            width: 29.97vw;
            height: 27.6vw;
        }

        &Mobile {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &Img3 {
        display: none;

        @media (min-width: 996px) {
            display: block;
            position: absolute;
            left: 0px;
            top: 55px;
            height: 15.1vw;
            width: 10.7vw;
            max-height: 290px;
            max-width: 207px;
            object-fit: contain;
            object-position: center;
            z-index: -1;
        }
    }

    &Img6 {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        object-fit: contain;
        object-position: center;
        z-index: -1;
    }

    &Img7 {
        position: absolute;
        left: -48px;
        object-fit: contain;
        object-position: center;
        transform: translateY(67%);
        object-fit: contain;
        object-position: center;
        bottom: 0;
        z-index: -1;
        max-width: 422px;
        max-height: 468px;
        width: 21.97vw;
        height: 24.375vw;
    }

    &Img8 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(28%) translateX(-50%);
        object-fit: contain;
        object-position: center;
        max-width: 386px;
        max-height: 327px;
        z-index: -1;
    }

    &Img9 {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            position: absolute;
            object-fit: contain;
            object-position: center;
            z-index: 2;
            left: 0;
            top: 0;
            transform: translate(-48%, -43%) rotate(-25deg);
        }
    }

    &Img18 {
        position: absolute;
        right: 0;
        z-index: 1;
        bottom: 0;
        transform: translate(72px, 106px);

    }

    &Img10 {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            position: absolute;
            object-fit: contain;
            object-position: center;
            z-index: -3;
            right: 0;
            transform: translate(23%, -50%) rotate(30deg);
            top: 0;
        }
    }

    &ImgRight {
        position: absolute;
        object-fit: contain;
        object-position: center;
        z-index: -2;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-73%);
        width: 380px;

        @media (min-width: 1200px) {
            transform: translateY(-38%);
            width: auto;
        }
    }

    &ImgLeft {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            z-index: -2;
            position: absolute;
            left: -44px;
            top: 0%;
            height: 651px;
            transform: translateY(-50%) scale(3);
            width: 431px;
        }
    }

    &ImgXZ {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            z-index: -3;
            position: absolute;
            right: 0px;
            top: 0%;
            max-width: 400px;
            max-height: 560px;
            width: 20.83vw;
            height: 29.16vw;
            transform: translateY(-58%);

        }
    }

    &ImgRing {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            position: absolute;
            object-fit: contain;
            object-position: center;
            z-index: -1;
            position: absolute;
            left: 50%;
            bottom: 0%;
            transform: translateY(-65%) translateX(14%);
        }
    }

    &Preview {
        width: 100%;
        max-height: 652px;

        @media screen and (min-width: 744px) {
            display: none;
        }

        @media screen and (min-width: 996px) {
            display: block;
        }
    }

    &FooterPreview {
        width: 70%;
        height: 270px;
        margin-left: 20px;
    }

    &BannerTitle {
        max-width: 638px;
        margin: 0 auto;

    }



    &DecisionsPreview {
        width: 100%;
        margin: 0 auto;

        @media (min-width: 996px) {
            max-width: 872px;
            max-height: 591px;
            height: 30.7vw;
            width: 45.41vw;
            position: absolute;
            top: 0;
            left: 46px;
        }
    }

    &CooperationPreview {
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        @media (min-width: 996px) {
            max-width: 566px;
            max-height: 461px;
        }
    }

    &ProjectVideo {
        max-height: 460px;
        width: 100%;
        min-height: 270px;

        @media (min-width: 996px) {
            height: 460px;
        }
    }



}

.base {
    &OrderColumnRight {
        order: 2;
        margin-top: 32px;

        @media (min-width: 996px) {
            order: 0;
            margin-top: 0px;
        }
    }

    &OrderColumnLeft {
        order: 1;
        margin-top: 0px;

        @media (min-width: 996px) {
            order: 0;
        }
    }
}


.boxSmall {
    padding-bottom: 70px;
}

.box {
    padding-bottom: 60px;

    @media (min-width: 1200px) {
        padding-bottom: 121px;
    }
}

.box380 {
    padding-bottom: 60px;

    @media (min-width: 1200px) {
        padding-bottom: 19.79vmax;
    }

    @media (min-width: 2561px) {
        padding-bottom: 400px;
    }

}

.box400 {
    padding-bottom: 60px;

    @media (min-width: 1200px) {
        padding-bottom: 400px;
    }
}


.itemCooperationInfoColumnRight {
    padding-bottom: 40px;

    @media (min-width: 1200px) {
        padding-left: 7.2vw;
    }

    @media (min-width: 1920px) {
        padding-left: 140px;
    }
}

.rowList {
    padding-top: 30px;
    justify-content: space-between;

    @media screen and (min-width: 996px) {
        padding-top: 80px;
    }
}

.doorables {
    &BannerBody {
        color: #fff;

        @media (min-width: 996px) {
            transform: translateY(-64px);
        }
    }

    &BannerTitle {
        color: #fff;
    }

    &BannerQute {
        border-color: #fff;
    }

    &AnalyticPreview {
        width: auto;
        height: auto;
        max-width: 290px;
        max-height: 197px;
        margin: 0 auto;

        @media screen and (min-width: 996px) {
            max-width: 697px;
            max-height: 475px;

        }
    }

    &AnalyticWrapPreviews {
        display: flex;
        justify-content: space-between;
        padding-top: 00px;
        flex-direction: column;

        @media (min-width: 744px) {
            flex-direction: row;
        }

        @media (min-width: 996px) {
            padding-top: 50px;
        }

    }

    &AnaliticColumnLeft {
        @media (min-width: 996px) {
            padding-right: 26px;
        }

        @media (min-width: 1280px) {
            @include adaptive-var("padding-right", 160, 26, 1);
        }

    }

    &AnaliticSection {
        position: relative;
        @include adaptive-var("padding-top", 100, 40, 1);
    }

    &BoxProgress {
        position: relative;
        padding-top: 20px;

        @media (min-width: 1200px) {
            padding-top: 208px;
        }
    }

    &ProgressSubColumnRight {
        order: 2;
    }

    &ProgressSectionFirstColumnLeft {
        @media (min-width: 1200px) {
            max-height: 603px;
            height: 31.4vw;
        }
    }

    &ProgressSectionFirstColumnRight {
        align-self: flex-start;
        display: flex;
    }


    &ProgressSectionLastRow {
        @media (min-width: 1200px) {
            margin-top: -5vw;
        }
    }

    &ProgressSectionLastColumnLeft {

        @media (min-width: 1200px) {
            max-height: 634px;
            height: 33.02vw;
        }
    }

    &ProgressSectionLastColumnRight {
        align-self: center;
        display: flex;

        @media (min-width: 1200px) {
            padding-right: 78px;
        }
    }

    &ProgressArowFirst {
        display: flex;
        justify-content: center;
        margin-top: -8vw;
        position: relative;
        z-index: -2;

        @media (min-width: 2561px) {
            margin-top: 0;
        }

        &Svg {
            width: 23.28vw;
            height: 17.92vw;
            max-width: 447px;
            max-height: 344px;
        }
    }

    &ProgressArowLast {
        display: flex;
        justify-content: center;
        margin-top: -4vw;
        position: relative;
        z-index: -2;

        @media (min-width: 2561px) {
            margin-top: 0;
        }

        &Svg {
            width: 23.96vw;
            height: 18.1vw;
            max-width: 460px;
            max-height: 346px;
        }
    }

    &ProgressSubPreview {
        width: 100%;

        @media (min-width: 1200px) {

            transform: scale(1.5);
        }

        @media (min-width: 2500px) {

            transform: scale(1);
        }
    }

    &Img2 {
        position: absolute;
        aspect-ratio: 1/1;
        right: 0;
        top: 50%;
        z-index: -2;
        transform: translateY(-30%);
    }

    &Img3 {
        position: absolute;
        aspect-ratio: 1/1;
        right: 0;
        top: 50%;
        z-index: -2;
        transform: translateY(-30%);
    }

    &Img4 {
        position: absolute;
        aspect-ratio: 1/1;
        left: 0px;
        top: 0;
        z-index: -1;
    }

    &Img5 {
        position: absolute;
        aspect-ratio: 1/1;
        right: 0;
        bottom: -20%;
        z-index: -1;
    }

    &Img8 {
        width: 100%;
        margin: 0 auto;

        @media (min-width: 996px) {
            max-width: 926px;
            max-height: 634px;
            height: 33.02vw;
            width: 48.22vw;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }


    &Img11 {
        position: absolute;
        aspect-ratio: 1/1;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    &Img12 {
        position: absolute;
        aspect-ratio: 1/1;
        left: 0;
        top: 15%;
        z-index: -1;
    }

    &Img13 {
        position: absolute;
        aspect-ratio: 1/1;
        left: 0;
        top: 50%;
        z-index: -2;
        transform: translateY(-30%);
        height: 122%;

        @media (min-width: 1200px) {
            transform: translateY(-50%);
            height: auto;
        }
    }

    &Img15 {
        aspect-ratio: 2/1;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }

    &Img16 {
        width: 113%;
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
        object-position: center;
        margin-left: -3%;
    }

    &DecisionsWrapPreview {
        position: relative;

        &::before {
            content: "";
            background-color: #3EF0D7;
            border-radius: 100%;
            width: 74px;
            height: 74px;
            position: absolute;
            left: -22%;
            top: -34px;
        }
    }

    &DecisionsPreview {
        width: 100%;
        margin: 0 auto;

        @media (min-width: 996px) {
            max-width: 1060px;
            max-height: 603px;
            height: 31.4vw;
            width: 55.2vw;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }

    &ResultsSection {
        @media (min-width: 996px) {
            margin-top: 110px;
            min-height: 750px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &Title {
            text-align: center;
        }

        &Row {
            justify-content: space-between;
        }
    }
}

.magnit {

    &BannerTitle {
        width: 186px;
        word-wrap: break-word;
        margin: 0 auto;

        @media (min-width: 744px) {
            width: 382px;
        }

        @media (min-width: 996px) {
            width: auto;
        }
    }

    &BannerRoot {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        min-height: 355px;

        @media (min-width: 540px) {
            background-size: cover;
        }

        @media (min-width: 744px) {
            height: 838px;
        }

        @media (min-width: 1200px) {
            height: 43vmax;
        }
    }

    &SectionHeader {
        padding-bottom: 100px;
    }

    &Img10 {
        position: absolute;
        z-index: -1;
        right: 0;
        top: -150px;
        aspect-ratio: 1/1;
    }

    &Img6 {
        position: absolute;
        z-index: -1;
        left: -136px;
        bottom: 0px;
        transform: rotate(48deg);
        aspect-ratio: 1/1;
    }

    &Img8 {
        position: absolute;
        z-index: -1;
        right: -146px;
        top: 0px;
        transform: rotate(36deg);
        aspect-ratio: 1/1;
    }


    &Img9 {
        position: absolute;
        z-index: -1;
        right: -118px;
        bottom: -70px;
        transform: rotate(-34deg);
        aspect-ratio: 1/1;
    }


    &Img11 {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        aspect-ratio: 1/1;
    }

    &Img12 {
        position: absolute;
        z-index: -1;
        left: 0px;
        top: 0px;
        aspect-ratio: 1/1;
    }

    &Img7 {
        position: absolute;
        z-index: -1;
        right: 0px;
        top: 50%;
        transform: translate(212px, -50%) rotate(45deg);
        aspect-ratio: 1/1;
    }

    &GradientPink {
        width: 614px;
        height: 614px;
        background: #FF2292;
        filter: blur(408.5px);
        position: absolute;
        top: 0px;
        left: -444px;
        z-index: -2;
    }

    &GradientPinkFooter {
        width: 537px;
        height: 614px;
        transform: translateY(-100%);
        top: 0;
        background: #FF2292;
        filter: blur(408.5px);
        z-index: -2;
        right: 0px;
        position: absolute;
    }

    &GradientBlue {
        width: 718px;
        height: 718px;
        background: #00F1ED;
        filter: blur(613.5px);
        position: absolute;
        top: 0px;
        right: -216px;
        z-index: -2;
    }

    &GradientGreen {
        width: 500px;
        height: 500px;
        left: -176px;
        top: 50%;
        background: #05F100;
        filter: blur(613.5px);
        position: absolute;
        z-index: -2;
        transform: translateY(60%);
    }

    &ProjectPreview {
        max-width: 650px;
        max-height: 715px;
        width: 100%;
        height: 100%;

        @media screen and (min-width: 744px) {
            display: none;
        }

        @media screen and (min-width: 996px) {
            display: block;
        }
    }

    &CooperationPreview {
        display: none;

        @media screen and (min-width: 996px) {
            display: block;
            position: absolute;
            left: 40px;
            top: 0;
            transform: translateY(-250px);
            max-width: 521px;
            max-height: 990px;
            width: 27vw;
            height: 51vw;
        }

        @media screen and (min-width: 1200px) {
            left: 160px;
        }
    }

    &ProjectFooter {
        display: flex;
        flex-direction: row;
        margin-top: 60px;
    }

    &ProjectFooterPreview {
        width: 70%;
        height: 259px;
        margin-left: 20px;
    }

    &ProjectFooterTabletRoot {
        justify-content: space-between;
    }

    &ProjectFooterTabletTitle {
        padding-top: 0px;
    }

    &ProjectColumnLeft {
        padding-bottom: 40px;

        @media (min-width: 1200px) {
            padding-bottom: 0px;
            padding-right: 7.2vw;
        }

        @media (min-width: 1920px) {
            padding-right: 140px;
        }
    }

    &AnalyticPreview {
        width: 100%;
        height: 100%;

        @media screen and (min-width: 744px) {
            max-width: 581px;
            max-height: 581px;
            margin: 0 auto;
            padding-left: 20px;
        }

        @media screen and (min-width: 996px) {
            padding-left: 0px;
        }

    }

    &AnalyticColumnRight {
        @media (min-width: 996px) {
            padding-left: 65px;
        }
    }

    &AnalyticColumnLeft {
        padding-right: 0px;
    }

    &AnalyticRoot {
        flex-direction: column;

        @media screen and (min-width: 744px) {
            flex-direction: row;
        }
    }

    &Video {
        width: 100%;
        max-height: 700px;
        min-height: 270px;
        height: 36.45vw;
    }

    &CooperationRoot {
        padding-top: 70px;

        @media screen and (min-width: 996px) {
            padding-top: 116px;
            padding-bottom: 230px;
            position: relative;
            overflow: hidden;
        }
    }

    &ItemColumnPreview {
        display: flex;
        justify-content: center;

        @media (min-width: 1200px) {
            width: 40% !important;
        }

        @media (min-width: 1530px) {
            width: 70% !important;
        }
    }

    &ItemPreview {
        @media (min-width: 744px) {
            width: 100%;
            max-height: 652px;
        }

        @media (min-width: 1200px) {
            transform: scale(1.8);
            margin-left: 100px;
        }

        @media (min-width: 1530px) {
            width: 100%;
            transform: scale(1);
            margin-left: 0px;
        }

    }
}

.shot {

    &BannerRoot {
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 355px;

        @media (min-width: 744px) {
            height: 838px;
        }

        @media (min-width: 1200px) {
            height: 43vmax;
        }
    }

    &BannerBody {
        transform: translateY(-18px);

        @media (min-width: 774px) {
            transform: translateY(0px);
        }
    }

    &Img2 {
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        aspect-ratio: 1/1;
        transform: translate(-13.44vmax, 34%) rotate(60deg);
        max-width: 992px;
        width: 51.66vw;
        max-height: 847px;
        height: 44.11vw;

        &Mobile {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
            object-position: center;
            max-width: 290px;
            max-height: 220px;
            margin: 0 auto;
        }
    }


    &Img3 {
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        aspect-ratio: 1/1;

        &Mobile {
            width: 100%;
            object-fit: contain;
            object-position: center;
            position: absolute;
            z-index: -1;
            left: 0;
            bottom: 0;
        }
    }


    &Img5 {
        position: absolute;
        z-index: -1;
        right: 0;
        top: 50%;
        transform: translateY(-65%);
        aspect-ratio: 1/1;
        max-width: 503px;
        max-height: 878px;
        width: 26.20vw;
        height: 45.73vw;
    }

    &BoxHeader {
        @include adaptive-var("padding-top", 208, 16, 1);
    }

    &BoxAnalytics {
        padding-bottom: 305px;
    }


    &BoxProgress {
        @include adaptive-var("padding-top", 149, 40, 1);
    }

    &AnalyticsDesc {
        @media (min-width: 996px) {
            padding: 0px 66px;
        }
    }

    &ProgressFirstColumnLeft {
        max-width: 500px;
    }

    &ProgressFirstColumnRight {
        order: 2;
        display: flex;
    }

    &ProgressColumnRight {
        max-width: 489px;

        @media (min-width: 996px) {
            padding-bottom: 76px;
        }

        &Footer {
            padding-top: 30px;
        }
    }

    &ProgressColumnLeft {
        display: flex;
        justify-content: center;
    }

    &ProgressPreview {
        max-width: 411px;
        max-height: 251px;
        margin: 0 auto;

        @media (min-width: 996px) {
            max-width: 1073px;
            max-height: 926px;
            aspect-ratio: 2/1;
            width: 55.88vw;
            height: 48.33vw;
        }
    }

    &ProgressPreviewTwo {
        aspect-ratio: 1/1;
        max-width: 924px;
        max-height: 515px;
        width: 48.12vw;
        height: 26.82vw;
        bottom: 0;
        left: 37px;
        position: absolute;
        z-index: -1;

        &Video {
            position: absolute;
            z-index: -1;
            bottom: 0;
            max-width: 596px;
            max-height: 330px;
            width: 31.1vw;
            height: 17.19vw;
            left: 0;
            transform: translate(17.99vmax, -104%);

            @media (min-width: 1520px) {
                transform: translate(25.99vmax, -104%);
            }
        }
    }

    &ProgressRow {
        justify-content: space-between;
        @include adaptive-var("padding-top", 117, 14, 1);
    }

    &Arrow {
        display: flex;
        justify-content: center;
        padding-right: 148px;
        margin-top: -100px;
    }

    &Img7 {
        width: 100%;
        max-height: 736px;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    &RowList {
        justify-content: space-between;
    }

    &Img8 {
        aspect-ratio: 1/1;
        object-fit: contain;
        object-position: center;
        width: 100%;
    }

    &Img9 {
        position: absolute;
        right: 0;
        height: 703px;
        width: 903px;
        top: 0;
        transform: translateY(-258px);
        z-index: -2;
        aspect-ratio: 1/1;
    }

    &Img10 {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -2;
        aspect-ratio: 1/1;
    }

    &Img11 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -2;
        aspect-ratio: 1/1;
        transform: translateY(36%);
    }

    &Img12 {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -2;
        aspect-ratio: 1/1;
        transform: translateY(-50%);
    }

    &Img13 {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: -2;
        aspect-ratio: 1/1;
        transform: translate(-50%, -50%);

        &Wrap {
            display: flex;
            width: 100%;
            position: relative;
        }
    }

    &Img14 {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -2;
        aspect-ratio: 1/1;
        transform: translateY(-50%);
    }

    &Img15 {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -2;
        aspect-ratio: 1/1;
        width: 100%;
    }

    &Img16 {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -2;
        aspect-ratio: 1/1;
        width: 100%;
    }

    &ResultTitle {
        text-align: left;
        padding-top: 40px;

        @media (min-width: 996px) {
            text-align: center;
        }
    }
}

.zuru {

    &BannerRoot {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 355px;

        @media (min-width: 774px) {
            height: 838px;
        }

        @media (min-width: 1200px) {
            height: 43vmax;
        }
    }

    &BannerBody {
        transform: translateY(-18px);

        @media (min-width: 774px) {
            transform: translateY(0px);
        }
    }

    &BannerTitle {
        width: 290px;
        margin: 0 auto;

        @media (min-width: 600px) {
            width: auto;
        }
    }

    &BanerDecorRight {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -2;
    }

    &BanerDecorLeft {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -2;
    }

    &ProjectSection {
        position: relative;
        @include adaptive-var("padding-bottom", 150, 40, 1);

    }

    &AnalyticColumnLeft {
        @media (min-width: 1200px) {
            max-height: 780px;
            height: 40.63vw;
        }
    }

    &AnalyticColumnRight {
        @media (min-width: 1200px) {
            margin-right: 100px;
            padding-left: 65px;
        }
    }

    &AnalyticRoot {
        @media (min-width: 1200px) {
            margin-top: -5vw;
        }
    }

    &AnalyticPreview {
        width: 128%;
        margin-left: -28%;

        @media (min-width: 1200px) {
            position: absolute;
            left: 0px;
            top: 0px;
            max-width: 856px;
            max-height: 780px;
            width: 45.58vw;
            height: 100%;
            margin-left: unset;
        }
    }

    &Img3 {
        display: none;

        @media (min-width: 996px) {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            max-width: 385px;
            max-height: 510px;
            height: 26.56vw;
            width: 20.05vw;
        }
    }

    &Img5 {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        transform: translateY(-20%);
        max-width: 502px;
        max-height: 725px;
        width: 12vw;
        height: 32.8vw;

        @media (min-width: 1432px) {
            width: 16vw;
            height: 37.8vw;
        }
    }

    &Img19 {
        position: absolute;
        left: 0;
        bottom: -82px;
        max-width: 876px;
        max-height: 506px;
        width: 28vw;
        height: 18.4vw;
        z-index: -1;

        @media (min-width: 1530px) {
            width: 45vw;
            height: 26.4vw;
            bottom: -110px;
        }
    }

    &ProgressSection {
        position: relative;


        @media (min-width: 996px) {
            margin-top: -14vw;
        }

        @media (min-width: 2500px) {
            margin-top: -4vw;
        }

    }

    &ProgressSectionCloumnRight {
        order: 2;
        display: flex;

        @media (min-width: 1200px) {
            height: 61.92vw;
            max-height: 1189px;
            order: 1;
        }
    }

    &Img6 {
        @media (min-width: 1200px) {
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
            max-width: 1075px;
            max-height: 1189px;
            width: 55.99vw;
            height: 100%;
        }

    }

    &Img7 {
        display: none;

        @media (min-width: 1430px) {
            display: block;
            position: absolute;
            top: 50%;
            z-index: -1;
            transform: translateY(-66%);
            max-width: 384px;
            max-height: 506px;
            width: 12vw;
            height: 18.35vw;
        }


    }

    &Img8 {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            z-index: -2;
            transform: translateY(-50%);
        }

    }

    &Img9 {
        max-width: 450px;
        max-height: 450px;
        position: absolute;
        width: 23.44vw;
        height: 23.44vw;
        width: 16.44vw;
        height: 16.44vw;
        top: -13vw;
        left: 50%;
        transform: translateX(0%);
        z-index: -1;

        @media (min-width: 1530px) {
            width: 23.44vw;
            height: 23.44vw;
            top: -15vw;
            transform: translateX(-18%);
        }

        @media (min-width: 1921px) {
            top: -50%;
        }
    }

    &Img10 {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    &Img11 {
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        max-width: 434px;
        max-height: 615px;
        width: 22.60vw;
        height: 32.03vw;
    }

    &Img12 {
        position: absolute;
        z-index: -1;
        left: 0px;
        top: calc(50% - 190px);
        transform: translateY(-50%);
    }

    &Img15 {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translateX(-52%) translateY(0);

        @media (min-width: 1300px) {
            transform: translateX(-120%) translateY(0);
        }
    }

    &Img14 {
        display: none;

        @media (min-width: 1433px) {
            display: block;
            position: absolute;
            z-index: -1;
            left: 0px;
            max-width: 250px;
            max-height: 580px;
            width: 13.01vw;
            height: 30.21vw;
        }

    }

    &ProgressInfoTitle {
        @include adaptive-var("padding-top", 114, 40, 1);
    }

    &ProgressFooterSection {
        position: relative;
        @include adaptive-var("padding-bottom", 190, 40, 1);

        @media (min-width: 1200px) {
            margin-top: -200px;
        }
    }

    &ProgressFooterSectionCloumnLeft {
        @media (min-width: 1200px) {
            width: 36% !important;
        }
    }

    &ProgressFooterSectionCloumnRight {
        @media (min-width: 996px) {
            padding-left: 40px;
        }

        @media (min-width: 1200px) {
            padding-left: 100px;
        }
    }

    &Video {
        width: 100%;
        max-height: 700px;
        min-height: 270px;
        height: 36.45vw;
    }

    &ProgressVideoSection {
        position: relative;

        margin-bottom: 50px;

        @media (min-width: 996px) {
            padding-bottom: 250px;
        }
    }

    &Img16 {
        width: 290px;
        height: 375px;
        margin: 0 auto;

        @media (min-width: 1280px) {
            max-width: 628px;
            max-height: 815px;
            width: 32.7vw;
            height: 42.44vw;
            transform: translateY(44px);
        }

    }

    &ResultRoot {
        @media (min-width: 996px) {
            margin-top: -15vw;
        }

        @media (min-width: 2500px) {
            margin-top: -4vw;
        }
    }

    &ResultColumnLeft {

        order: 0 !important;
        display: flex;

        @media (min-width: 1200px) {

            @include adaptive-var("padding-left", 120, 0, 1);
        }
    }

    &ResultList {
        justify-content: space-between;
    }

    &Img17 {
        position: absolute;
        z-index: -2;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &Img18 {
        position: absolute;
        z-index: -2;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &BodyBanner {
        align-items: flex-start;
        top: -10px;

        @media (min-width: 996px) {
            align-items: center;
            top: 0
        }
    }

    &BodyDesc {
        line-height: 100%;
    }

    &Img20 {
        position: absolute;
        right: 0px;
        top: -6%;
        z-index: 1;
        height: 144px;

        @media (min-width: 744px) {
            height: auto;
            top: -26%;
        }

        @media (min-width: 996px) {
            display: none;
        }
    }

    &Img22 {
        margin: 0 auto;
        width: 236px;
        height: 341px;
        margin-bottom: 20px;
    }

    &Img21 {
        width: 109%;
        object-fit: contain;
        object-position: center;
        margin-left: -3%;

        @media (min-width: 640px) {
            width: 100%;
            margin-left: 202px;
        }
    }

    &Img23 {
        position: absolute;
        right: 0;
        top: 4%;
        z-index: -2;
    }

    &Img24 {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -2;
        transform: translateY(-32%);
    }

    &Img25 {
        position: absolute;
        left: 0;
        z-index: -2;
    }


    &ResultColumnRight {
        display: flex;
        justify-content: center;
    }
}

.rainbocorns {
    &Body {
        padding-top: 20px;

        @media (min-width: 1200px) {
            padding-top: 120px;
        }
    }

    &Img1 {
        position: absolute;
        right: 36px;
        bottom: -55px;
        z-index: -1;
        width: 84px;
        height: 79px;

        @media (min-width: 1200px) {
            bottom: -226px;
            right: 45%;
            width: auto;
            height: auto;
        }
    }



    &Img2 {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -2;
        transform: translateY(-60%);

    }

    &Img3 {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            z-index: -2;
            transform: translateY(-60%);
        }
    }

    &Img5,
    &Img6 {
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    &Img5 {
        left: 0;
        max-height: 915px;
        max-width: 525px;
        width: 27.34vw;
        height: 47.66vw;

    }

    &Img6 {
        max-height: 915px;
        max-width: 525px;
        width: 27.34vw;
        height: 47.66vw;
        right: 0;
    }

    &Img18 {
        max-width: 290px;
        max-height: 304px;
        aspect-ratio: 1/1;
        margin: 0 auto;
        margin: 0 auto;
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &AnalyticsSection {
        position: relative;

        @media (min-width:996px) {
            padding-top: 110px;
            padding-bottom: 240px;
        }
    }

    &AnalyticsDesc {
        @media (min-width: 996px) {
            padding: 0px 12px;
        }
    }

    &Background {
        position: relative;
        background-repeat: repeat-y;
    }

    &ProgressSection {
        position: relative;

        @media (min-width: 1200px) {
            padding-bottom: 170px;
        }
    }

    &Img7 {
        display: flex;
        margin: 20px auto;
        max-height: 214px;
        max-width: 290px;

        @media (min-width: 996px) {
            max-height: 897px;
            max-width: 1082px;
            position: absolute;
            top: calc(50% - 52px);
            right: 0;
            height: 38.7vw;
            width: 43.35vw;
            z-index: -1;
            transform: translateY(-50%);
            margin: 0;
        }

        @media (min-width: 1200px) {
            height: 38.7vw;
            width: 48.35vw;
        }

        @media (min-width: 1700px) {
            height: 46.7vw;
            width: 56.35vw;
        }
    }

    &ProgressSectionCloumnLeft {
        @media (min-width: 1200px) {
            width: 62% !important;
        }

        @media (min-width: 1530px) {
            width: 60% !important;
            padding-right: 40px;
        }

        @media (min-width: 1700px) {
            width: 50% !important;
            @include adaptive-var("padding-right", 146, 40, 1);
        }

    }

    &ProgressSectionCloumnRight {
        order: 2;
    }

    &Img9,
    &Img8 {
        position: absolute;
        z-index: -1;

    }

    &Img8 {
        top: -122px;
        left: -6%;
        max-height: 318px;
        transform: rotate(46deg);
    }

    &Img9 {
        bottom: -95px;
        left: -5.3%;
        transform: rotate(17deg);
    }

    &Img10 {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -2;
        transform: translateY(-50%);
    }


    &ProgressRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @media (min-width: 996px) {
            flex-direction: row;
        }
    }

    &ProgressFirstColumnLeft {
        width: 100%;
        order: 2;

        @media (min-width: 996px) {
            order: 0;
        }
    }

    &ProgressFirstColumnRight {
        width: 100%;

        @media (min-width: 996px) {
            max-width: 555px;
            display: flex;
            justify-content: flex-end;
        }
    }

    &Img11 {

        width: 100%;

        @media (min-width: 1200px) {
            margin-left: -40px;
        }
    }

    &ProgressLastColumnRight {
        width: 100%;
    }

    &ProgressLastColumnLeft {
        max-width: 555px;
        display: flex;
        justify-content: flex-start;
    }

    &ProgressLastRow {
        @media (min-width: 1200px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 1520px) {
            padding-top: 180px;
            padding-bottom: 180px;
        }
    }

    &Img12 {
        width: 100%;
        max-width: 781px;

        @media (min-width: 1200px) {
            margin-left: 40px;
            max-height: 441px;
        }
    }

    &Title {
        @media (min-width: 996px) {
            margin-bottom: 40px;
        }

        @media (min-width: 1200px) {
            margin-bottom: 115px;
        }
    }

    &Video {
        width: 100%;
        max-height: 700px;
        min-height: 270px;
        height: 36.45vw;
    }

    &ResultList {
        justify-content: space-between;
    }

    &ResultRoot {
        margin-top: -32px;

        @media (min-width: 1200px) {
            margin-top: 0;
            padding-top: 80px;
        }

        @media (min-width: 1520px) {
            margin-top: 0;
            padding-top: 130px;
        }
    }

    &Img14 {
        width: 100%;
        object-fit: contain;
    }

    &Img15,
    &Img17 {
        position: absolute;
        right: 0;

        @media (min-width: 1200px) {
            right: -48px;
        }

        @media (min-width: 1600px) {
            right: 0;
        }
    }

    &Img15 {
        z-index: -2;
        top: 50%;
        transform: translateY(-50%);
    }

    &Img16 {
        position: absolute;
        z-index: -2;
        left: 0;
        transform: translateY(-50%);
        top: 50%;
    }

    &Img17 {
        z-index: -1;
        top: 0;
        transform: translateY(-12%);
    }

    &Img22 {
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        right: -278px;
        position: absolute;
    }

    &BannerBody {
        color: #fff;

    }

    &BannerTitle {
        color: #fff;
        max-width: 826px;
        margin: 0 auto;
    }

    &BannerQute {
        border-color: #fff;
    }

    &BannerRoot {
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        @media (min-width: 560px) {
            background-position: top;
            background-size: auto;

        }

        @media (min-width: 774px) {
            background-size: cover;
            background-position: center;
            height: 838px;
        }

        @media (min-width: 1200px) {
            height: 43vmax;
        }
    }

    &Img23 {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        transform: translateY(-100%);
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &Img24Top {
        position: absolute;
        z-index: -2;
        left: 50%;
        transform: translateX(-50%);
        top: 20%;
    }
}

.burger {
    &BannerRoot {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (min-width: 560px) {
            background-position: left;
        }

        @media (min-width: 774px) {
            background-size: cover;
            background-position: center;
            height: 838px;
        }

        @media (min-width: 1200px) {
            height: 43vmax;
        }
    }

    &Img1 {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
    }

    &Img1_1 {
        position: absolute;
        right: 86px;
        top: 14%;
        z-index: -1;
    }

    &Img3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: -2;
    }

    &BoxProject {
        padding-top: 40px;

        @media (min-width: 1200px) {
            padding-top: 148px;
        }
    }

    &Img2 {
        width: 290px;
        height: 304px;
        object-fit: cover;
        object-position: center;
        max-width: 624px;
        max-height: 660px;
        margin: 0 auto;
        display: flex;

        @media (min-width: 996px) {
            width: 100%;
            height: auto;
            margin: auto;
        }
    }

    &BoxDecisions {
        padding-top: 20px;

        @media (min-width: 1200px) {
            padding-top: 181px;
        }

    }

    &Img5 {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }

    &ProgressSection {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        position: relative
    }

    &Img4 {
        position: absolute;
        right: 0;
        top: -3.6vw;
        z-index: -1;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &ProgressSectionTitle {
        padding-bottom: 20px;

        @media (min-width: 1200px) {
            padding-bottom: 70px;
        }

    }

    &ProgressSectionColRight {
        @media (min-width: 996px) {
            max-width: 489px;
        }
    }

    &ProgressFirstBox {
        @media (min-width: 1200px) {
            @include adaptive-var("padding-top", 812, 0, 0);
        }

        @media (min-width: 2561px) {
            padding-top: 1136px;
        }
    }

    &Img7 {
        max-width: 777px;
        max-height: 790px;
        aspect-ratio: 1/1;
        width: 100%;

        @media (min-width: 1520px) {
            margin-left: 55px;
        }
    }

    &ProgressSubColLeft {
        padding-right: 0px;

        @media (min-width: 996px) {
            padding-right: 100px;
        }

        @media (min-width: 1520px) {
            padding-right: 0px;
        }
    }

    &ProgressFooterColLeft {
        @media (min-width: 996px) {
            padding-right: 55px;
        }
    }

    &Img6 {
        max-width: 598px;
        max-height: 468px;
        width: 100%;
    }

    &BoxArrow {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            padding-top: 202px;
        }
    }

    &Arrow {
        display: none;

        @media (min-width: 1200px) {
            display: block;
            max-width: 657px;
            max-height: 561px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-63%, -63%);
            height: 400px;
        }

        @media (min-width: 1520px) {
            width: 657px;
            height: 561px;
        }
    }

    &ResultColumnLeft {
        @media (min-width: 1200px) {
            max-width: 1023px;
            max-height: 652px;
            width: 53.3vw;
            height: 34vw;
        }
    }

    &ResultColumnRight {
        padding-left: 0px;

        @media (min-width: 996px) {
            padding-left: 40px;
        }

        @media (min-width: 1200px) {
            padding-left: 80px;
        }

        @media (min-width: 1520px) {
            padding-left: 142px;
        }
    }

    &Img8 {
        max-width: 1023px;
        max-height: 652px;
        width: 100%;
        height: auto;

        @media (min-width: 1200px) {
            width: 53.3vw;
            height: 34vw;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &ResultList {
        justify-content: space-between;
    }

    &Img9 {
        max-width: 280px;
        max-height: 230px;
        aspect-ratio: 1/1;
        margin: 0 auto;
        display: flex;
    }

    &Img10 {
        max-width: 290px;
        max-height: 299px;
        aspect-ratio: 1/1;
        margin: 0 auto;
        display: flex;
    }

    &Img11 {
        position: absolute;
        right: 0;
        z-index: -2;
        top: 50%;
        transform: translateY(-26%);
    }

    &Img12 {
        position: absolute;
        right: 0;
        z-index: -2;
        top: 50%;
        transform: translateY(-50%);
    }

    &Img13 {
        position: absolute;
        left: 0;
        z-index: -2;
        top: 50%;
        transform: translateY(-40%);
    }

    &ProgressFooterColRight {
        padding-top: 30px;
        padding-bottom: 30px;
        order: 3;
        display: flex;

        @media (min-width: 996px) {
            order: 0;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
}


.title {
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    color: #FFA041;

    @media (min-width: 744px) {
        font-size: 24px;
    }

    @media (min-width: 996px) {
        font-size: 32px;
        text-align: center;
    }
}

.description {
    font-weight: 400;
    font-size: 16px;

    @media (min-width: 744px) {
        font-size: 20px;
    }

    @media (min-width: 996px) {
        font-size: 16px;
    }

    // @media (min-width:1700px) {
    //     font-size: 18px;
    // }

    @media (min-width:1860px) {
        font-size: 24px;
    }

}

.containerSingle {
    margin-left: 250px;
    margin-right: 250px;
    width: calc(100% - 500px);

}

.zmBannerRoot{
    background-size: cover;
    aspect-ratio: 2/1;
    height: auto!important;
    @media (min-width: 996px) {
        background-position: right;
    }
}
.zmGrid{
    @media (min-width: 1280px) {
        display: grid;
        justify-content: center;
        grid-template-columns: clamp(375px,42.6vw,818px) 1fr;
    }
}
.zmAnalyticWrapPreviews{
    display: flex;
    justify-content: flex-end;
}

.zmAnalyticPreview{
    width: 100%;
    height: 100%;
    width: clamp(301px,34.32vw,659px);
    height: clamp(302px,34.32vw,659px);
    margin: 40px auto;
    margin-top: 10px;

    @media (min-width: 1530px) {
        transform: translateY(clamp(-154px,-8.02vw,0px));
        margin: 0px auto;
        margin-top: 0px;
    }
}

.zmImg3{
    position: absolute;
    right: 0;
    bottom: -454px;
    width: auto;
    height: auto;
}

.zmGridOffsetLeft{
    padding-bottom: 20px;
    @media (min-width: 744px) {
        padding-bottom: 0px;
    }
    @media (min-width: 1280px) {
        display: grid;
        transform: translateX(-50px);
        grid-template-columns: 1fr clamp(375px,42.6vw,818px);
        align-items: center;
        margin-top: -100px;
    }
    @media (min-width: 1520px) {
        margin-top: clamp(-296px,-14vw,0px);
    }
}

.zmDescPreview{
    width: 100%;
    height: 100%;
    width: clamp(301px,34.62vw,684px);
    height: clamp(302px,34.62vw,684px);

    @media (min-width: 1280px) {
        transform: translateX(-100px);
    }
}

.zmGridDescTwo{
    @media (min-width: 1280px) {
        display: grid;
        transform: translateX(-50px);
        grid-template-columns: clamp(375px,36.4vw,699px) 1fr;
        align-items: center;
        margin-top: clamp(-164px,-8.54vw,0px);
    }
}
.zmDescWrapPreviews, .zmDescThreeWrapPreviews{
    display: none;

    @media (min-width: 1280px) {
        display: flex;
        justify-content: flex-start;
    }
}
 .zmDescThreeWrapPreviews{

    display: flex;
    justify-content: center;

    @media (min-width: 1280px) {
        justify-content: flex-start;
    }
}

.zmDescTwoWrapPreviews{
    display: none;
    @media (min-width: 1280px) {
        display: flex;
        justify-content: center;
    }

    @media (min-width: 1280px) {
        justify-content: flex-end;
    }
}
.zmDescTwoPreview{
    width: clamp(320px,41.4vw,795px);
    height: clamp(367px,51.35vw,986px);
    object-fit: cover;
}


.zmGridDescThree{
    padding-bottom: 40px;

    @media (min-width: 1280px) {
        padding-bottom: 0px;
        display: grid;
        grid-template-columns: 1fr clamp(375px,36.4vw,699px);
        align-items: center;
        gap: 25px;
        margin-top: -80px;
        margin-top: clamp(-280px,-14.5vw,0px);

    }
}

.zmDescThreePreview{
    position: relative;
    width: clamp(320px,37.91vw,728px);
    height: clamp(367px,44.84vw,861px);
}

.zmDescriptionThree{
    position: relative;
    span{
        color: #FFA041;
    }
}

.zmDescThreeWrapPreviews{
    position: relative;
}

.zmDescThreeDecor{
    width: 728px;
    height: 728px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 0;
    bottom: -250px;
}
.zmCampaignRoot{
    z-index: 2;
}
.zmCampaignTitle{
    font-size: 18px;
    @media(min-width: 744px){
        font-size: 20px;
    }
    @media(min-width: 996px){
        font-size: 28px;
    }
    @media (min-width: 1860px) {
        font-size: 32px;
    }
}

.zmCampaignDescription{
    font-size: 16px;
    max-width: 1419px;
    font-weight: 400;
    @media(min-width: 744px){
        font-size: 20px;
    }
    @media(min-width: 996px){
        font-size: 16px;
    }
    @media (min-width: 1860px) {
        font-size: 24px;
    }
}
.zmCampaignDecor{
    display: none;
    @media (min-width: 1280px) {
        position: absolute;
        width: clamp(284px,25.2vw,484px);
        height: clamp(118px,16.6vw,318px);
        top: 0px;
        right: 0px;
        display: block;
        transform: translateY(-100%);
        z-index: 0;
    }

    @media (min-width: 1530px) {
        transform: translateY(-94%);
    }
}
.zmStageTitle,.xsStageTitle{
    color: #FFA041;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
    @media (min-width: 1280px) {
        margin-bottom: 50px;
        margin-top: 0px;
        font-size: 20px;
    }
    @media (min-width: 1860px) {
        font-size: 24px;
    }
}
.zmStageYt{
    position: relative;
    z-index: 2;
    @media (min-width: 1280px) {
        display: grid;
        grid-template-columns:  clamp(375px,36.4vw,699px) 1fr;
        gap: 5px;
        align-items: center;
        margin-top: 47px;
        position: relative;
        z-index: 1;
        &.zmStageYtTwo{
            margin-top: -50px;
            padding-bottom: 50px;
        }
    }
}
.zmStageVk{
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 3;
    @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: 1fr clamp(375px,36.4vw,699px);
        align-items: center;
        gap: 20px;
        margin-top: -50px;
    }
    @media (min-width: 1520px) {
        margin-top: clamp(-160px,-10.41vw,0px);
    }
    @media (min-width: 1770px) {
        gap: 0px;
    }

    @media (min-width: 1860px) {
        margin-top: 0px;
    }
}
.zmList{
    font-size: 16px;
    font-weight: 400;
    @media (min-width: 744px) {
        font-size: 20px;
    }
    @media (min-width: 1280px) {
        font-size: 16px;
    }
    li{
        padding-bottom: 20px;
        @media (min-width: 744px) {
            padding-bottom: 12px;
        }
        span{
            color: #FFA041;
        }
    }
    @media (min-width: 1860px) {
        font-size: 24px;
    }
}
.zmStageYtPreview,.zmStageVkPreview, .zmStageYtTwoPreview{
    width: 100%;
    height: 100%;
}

.zmStageYtPreview{
    max-width: 811px;
    max-height: 691px;
    object-fit: contain;
    margin: 0 auto;
    display: flex;
}

.zmStageYtTwoPreview{
    max-width: 900px;
    max-height: 582px;
    margin: 0 auto;
    display: flex;
    @media (min-width: 1280px) {
        transform: translateX(100px);
    }
}
.zmStageVkPreview{

    display: none;
    @media (min-width: 744px) {
        max-width: 607px;
        max-height: 830px;
        display: flex;
        margin: 0 auto;
    }
}

.zmStageVkDecor{
    width: auto;
    height: auto;
    left: 0;
    bottom: -600px;
    position: absolute;
    z-index: 0;
}
.zmStageYtTwoDecor{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 0;
}
.zmVideo{
    width: 100%;
    max-height: 700px;
    min-height: 270px;
    height: 36.45vw;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: 744px) {
        margin-top: 70px;
        margin-bottom: 100px;
    }
}

.zmBg{
    width: 100%;
    height: clamp(322px,41.6vw,800px);
    max-height: 800px;
    object-fit: cover;
    object-position: center;
    margin-top: 20px;
    margin-bottom: 40px;
    @media (min-width: 744px) {
        margin-top: 70px;
        margin-bottom: 100px;
    }
}

.zmPaddingBottomResult{
    padding-bottom: 20px;
    white-space: pre-line;
    @media (min-width: 744px) {
        padding-bottom: 50px;
    }
}

.zmGridResults{
    
    @media (min-width: 744px) {
        grid-template-columns: clamp(375px,34vw,650px) 1fr;
    }
}
.zmImgResults{
    max-width: 698px;
    max-height: 636px;
    margin-top: 20px;
    @media (min-width: 744px) {
        margin-top: 0px;
    }
}

.sliderMobileWrap{
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 3;
    @media (min-width: 744px) {
        display: none;
    }
}

.sliderMobile{
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 10px;
    margin-bottom: -10px;
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.containerRight{
    width: 100%;
    margin: 0 auto;
    padding-right: var(--bs-gutter-x, 0.9375rem);
    padding-left: var(--bs-gutter-x, 0.9375rem);
    @media (min-width: 576px){
        max-width: 600px;
    }
    @media (min-width: 744px){
        max-width: 674px;
    }
    @media (min-width: 996px) {
        padding-right: 0;
        padding-left: 0;
        margin: unset;
        max-width: unset;
        margin-left: clamp(20px,13.02vw,250px);
        padding-right: clamp(20px,6.6vw,127px);
    }
    @media (min-width: 1925px) {
        max-width: 1436px;
        margin: 0 auto;
        padding-right: 0px;
      }
}

.zmBannerRoot{
    background-size: cover;
    aspect-ratio: 9/10;
    height: auto !important;
    @media (min-width: 996px) {
        aspect-ratio: unset;
        height: 43vmax !important;
        background-position: right;
    }
}
.xsGridRight{
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    @media (min-width: 996px) {
        margin-top: 0px;
        display: grid;
        grid-template-columns: clamp(375px,42.6vw,818px) 1fr;
        
    }
}

.xsGridRightColLeft{
    
    @media (min-width: 996px) {
        padding-right: 30px;
    }
}
.xsGridRightContentPreview{
    width: clamp(291px,40.72vw,694px);
    height: clamp(334px,41.51vw,797px);
    margin-top: 20px;
    @media (min-width: 996px) {
        margin-top: 0px;
    }
}
.xsAnalyticsDesc{
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: 996px) {
        margin-top: 40px;
        margin-bottom: 0px;
    }
}

.xsGridRightColLeftPreview{
    display: none;
    @media (min-width: 996px) {
        display: block;
        width: clamp(291px,41.51vw,782px);
        height: clamp(194px,27.23vw,523px);
        transform: translateX(46px);
    }
}

.xsGridOffset{
    margin: 0 auto;
    padding-right: var(--bs-gutter-x, 0.9375rem);
    padding-left: var(--bs-gutter-x, 0.9375rem);
    @media (min-width: 576px){
        max-width: 600px;
    }
    @media (min-width: 744px){
        max-width: 674px;
    }
    @media (min-width: 996px) {
        padding-right: 0;
        padding-left: 0;
        max-width: unset;
        margin: unset;
        display: grid;
        grid-template-columns: clamp(375px,42.6vw,818px) 1fr;
        align-items: center;
        gap: clamp(30px,13.64vw,262px);
    }
    @media (min-width: 1280px) {
        margin-top: -8%;
    }
    @media (min-width: 1560px) {
        margin-top: -12%;
    }
    @media (min-width: 1860px) {
        margin-top: -9%;
    }
    @media (min-width: 1925px) {
        max-width: 1920px;
        margin: 0 auto;
        margin-top: -5%;
    }
}

.xsGridOffsetContent{

    @media (min-width: 996px) {
        max-width: 578px;
        margin-right: 20px;
    }
}

.xsStageYt{
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1280px) {
        display: grid;
        grid-template-columns:  clamp(375px,36.4vw,699px) 1fr;
        gap: 5px;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 150px;
    }
}

.xsStageYtContent{
    position: relative;
    z-index: 3;
    @media (min-width: 1280px) {
        transform: translateY(-100px);
    }
}
.xsStageYtPreview{
    width: clamp(320px,58.28vw,1119px);
    height: clamp(270px,49.68vw,954px);
    object-fit: cover;
    border-radius: 0% 50% 50% 0%;
    position: relative;
    z-index: 1;
    @media (min-width: 1280px) {
        border-radius: 0%;
        transform: translateX(-128px);
    }
}

.xsStageVk{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @media (min-width: 1280px) {
        display: grid;
        grid-template-columns:  1fr clamp(375px,36.4vw,699px) ;
        gap: 25px;
        align-items: flex-end;
        margin-top: 66px;
    }
}

.xsStageVkPreview{
    width: clamp(320px,35.93vw,690px);
    height: clamp(228px,25.62vw,492px);
}
.xsStagePreviewGlobal{
    width: clamp(320px,75.31vw,1446px);
    height: clamp(228px,61.71vw,1185px);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-44%);
    z-index: 2;
}
.xsStageDecor{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.xsVideo{
    width: 100%;
    max-height: 700px;
    min-height: 270px;
    height: 36.45vw;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (min-width: 744px) {
        margin-top: 40px;
        margin-bottom: 70px;
    }
}

.xsStageTitle{
    margin-top: 30px;
    @media (min-width: 1280px) {
        margin-top: 100px;
    }
}

.xsStageDecorVideo{
    width: 100%;
    height: 420px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.xsStageVideoWrap{
    padding-bottom: 30px;
    @media (min-width: 966px){
        padding-bottom: 70px;
    }
}
.xsBg{
    margin-top: 0px;
    margin-bottom: 30px;
    @media (min-width: 744px){
        margin-top: 0px;
        margin-bottom: 40px;
    }
}

.xsGridResults{
    position: relative;
    z-index: 2;
    @media (min-width: 744px) {
        align-items: center;
        grid-template-columns: clamp(320px,23.95vw,460px) 1fr;
    }
}
.xsImgResultsDesktop{
    margin-top: 40px;
    @media (min-width: 744px) {
        margin-top: 0px;
        width: clamp(320px,40.31vw,774px);
        height: clamp(320px,18.80vw,361px);
    }

}

.xsDecorTwo,.xsDecorOne{
    position: absolute;
    left: 0px;
    
}
.xsDecorOne{
    z-index: 1;
    bottom: -250%;
}

.xsDecorTwo{
    z-index: 2;
    bottom: -50%;
    width: clamp(259px,23.90vw,459px);
    height: clamp(369px,29.63vw,569px);
}
.zIndex{
    position: relative;
    z-index: 4;
}

.xsDecorThree{
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
}
.xsDecorFour{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.xsProjectFooterWrap{
    position: relative;
    display: flex;
}
.xsProjectFooterDecor,.xsStageMobileDecor{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-28%);
    width: 100%;
    height: 100%;
}
.xsProjectFooterPreview{
    margin: 0 auto;
}
.xsStageMobileDecor{
    transform: translate(-50%,-70%);
}

.xsStageVkMobileDecor{
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0%, -10%);
}

.xsResultDecor{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 40px;
    left: 50%;
    transform: translate( -50%, 30%)
}