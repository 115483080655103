@import "../../styles/_variables.scss";

.menu-top {
    display: flex;
    align-items: center;
    min-height: 32px;

    &_offset {
        padding: 45px;

        @media screen and (min-width: 996px) {
            padding: 65px;
        }
    }

    &__mobile {
        display: none;

        & .box {
            height: 10px;
        }

        @media screen and (max-width: 1200px) {
            display: block;
            position: fixed;
            top: 0px;
            right: 0px;
            background-color: $background;
            width: 0px;
            height: 100%;
            display: flex;
            z-index: -1;
            visibility: hidden;
            transition: all 500ms ease-in;
            overflow-x: hidden;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &_mobile_active {
        z-index: $zIndexHeader - 1;
        width: 100%;
        visibility: visible;
        transition: all 500ms ease-in;

        @media screen and (min-width: 576px) {
            width: 50%;
        }
    }

    &__burger {
        z-index: 210;
        display: flex;
        flex-grow: 1;

        @media screen and (min-width: 1200px) {
            display: none;
        }
    }

    &__catalog {
        &-title {
            color: white;
        }
    }

    &__scroll {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 4px;
    }

    &_flex-end {
        &>div {
            justify-content: flex-end;
        }
    }
}