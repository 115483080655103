@import "../../styles/_variables.scss";

.footer {
    margin-top: 35px;
    padding-top: 50px;
    padding-bottom: 55px;
    background-color: $background;
    text-align: center;
    border-top: 1px solid #F0F0F0;

    @media screen and (min-width: 744px) {
        text-align: left;
        margin-top: 25px;
    }

    @media screen and (min-width: 996px) {
        margin-top: 130px;
    }
}

.menu {
    text-transform: uppercase;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 10px;
    column-gap: 6px;

    &>span {
        padding: 0;
        font-size: 16px;
        z-index: 2;
    }

    &>a {
        padding: 0px 10px;
        font-size: 16px;
        text-align: left;
    }

    @media (min-width: 744px) {
        justify-content: space-between;
        text-align: center;
    }

    @media (min-width: 996px) {
        column-gap: 0px;
    }

}

.copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    br {
        display: none;
    }

    @media screen and (min-width: 744px) {
        font-size: 16px;
    }

    @media screen and (min-width: 996px) {
        br {
            display: block;
        }
    }
}

.email {
    font-size: 24px;

    @media screen and (min-width: 1200px) {
        font-size: 34px;
    }

    @media screen and (min-width: 1530px) {
        font-size: 44px;
    }

    @media screen and (min-width: 1700px) {
        font-size: 48px;
    }
}

.flexBetween {
    display: flex;
    justify-content: space-between;
}

.columnBetween {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.box {
    height: 30px;
    width: 1px;

    @media screen and (min-width: 744px) {
        height: 25px;
    }

    @media screen and (min-width: 996px) {
        height: 70px;
    }
}

.switchLang {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 744px) {
        position: relative;
        justify-content: flex-start;
    }

    &>ul {
        position: absolute;
        z-index: 2;
        bottom: 42px;

        @media screen and (min-width: 996px) {
            bottom: unset;
        }
    }
}

.terabit {
    display: flex;
    justify-content: center;

    @media (min-width: 744px) {
        justify-content: flex-start;
    }
}