.custom-list {
    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 90%;
        padding-bottom: 10px;
        border-bottom: 1px solid #FFA041;

        @media (min-width: 996px) {
            font-size: 48px;
        }
    }

    &__list {
        padding-top: 10px;
    }

    &__item {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        color: #505255;
        padding-bottom: 5px;
        line-height: 22px;

        @media (min-width: 996px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (min-width: 1700px) {
            font-size: 24px;
            line-height: 33px;
        }
    }

    &__marker {
        margin-right: 6px;
    }
}