@import "../../styles/_variables.scss";

.titleWrap {
    text-align: center;
    padding-top: 40px;

    @media screen and (min-width: 744px) {
        padding-top: 100px;
    }
}

.title {
    padding-bottom: 0px;
    text-align: left;

    @media screen and (min-width:744px) {
        padding-bottom: 40px;
    }

    @media screen and (min-width: 996px) {
        text-align: center;
    }
}

.projectsList {
    --bs-gutter-y: 40px;
    margin-top: 0px;

    @media screen and (min-width: 1200px) {
        --bs-gutter-x: 40px;
        --bs-gutter-y: 40px;

    }

    @media screen and (min-width: 1520px) {
        --bs-gutter-x: 40px;
        --bs-gutter-y: 60px;
    }
}

.actions {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 996px) {
        padding-top: 94px;
    }
}

.container{
    margin-left: 250px;
    margin-right: 250px;
    max-width: calc(100% - 500px);
}