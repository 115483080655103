// colors
$primary_text: rgba(56, 56, 56, 1);
$secondary_text: rgba(255, 160, 65, 1);
$badge: #383838;
$black: #383838;
$write: #fff;
$link: #A25F4B;
$overlay: rgb(5 8 39 / 40%);
$desc: #646464;
$background: #FFFFFF;
$background_light: #E4E4E4;
$background_opacity: rgba(56, 56, 56, 0.6);
$border: #D0D0D0;
$gray: rgba(153, 153, 153, 1);
$hover: rgba(255, 160, 65, 1);
$gradient: linear-gradient(to left, rgba(255, 198, 61, 1), rgba(255, 243, 142, 1));
$gradient_hover: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));

$zIndexHeader: 1000;


$maxWidthContainer: 1520; // bootstrap max size container
$maxWidth: 1920; // max width  window

@mixin adaptive-var($property, $startSize, $minSize, $type) {
    $addSize: $startSize - $minSize;

    @if $type ==1 {
        #{$property}: $startSize + px;

        @media (max-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px)/#{$maxWidthContainer - 320}));
        }
    }

    @else if $type ==2 {
        #{$property}: $startSize + px;

        @media (min-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px)/#{$maxWidth - 320}));
        }
    }

    @else {
        #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px)/#{$maxWidth - 320}));
    }
}