@import "../../../styles/variables";

.competencies {
    &Title {
        color: $secondary_text;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &Row {
        padding-top: 30px;
        padding-bottom: 20px;
        row-gap: 37px;
    }

    &List {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (min-width: 744px) {
            gap: 20px;
            align-items: stretch;
        }
    }

    &Item {
        width: 100%;
        height: 100%;
        margin-bottom: 15px;

        @media screen and (min-width: 744px) {
            height: auto;
            width: calc(50% - 20px);
            min-height: 410px;
            margin-bottom: 0;
            align-items: stretch;
            display: flex;
        }


        @media screen and (min-width: 1200px) {
            height: 100%;
            min-height: 358px;
        }

        @media screen and (min-width: 1520px) {
            min-height: 410px;
        }
    }
}