@import "../../../styles/_variables.scss";

.profile {
    &__title {
        font-weight: 800;
        font-size: 40px;
        padding-bottom: 20px;
        line-height: 100%;

        @media screen and (min-width: 744px) {
            font-size: 80px;
            padding-bottom: 30px;

            &[data-lang='ru'] {
                font-size: 74px;
            }
        }

        @media screen and (min-width: 1200px) {
            font-size: 80px;

            &[data-lang='ru'] {
                font-size: 61px;
            }
        }

        @media screen and (min-width: 1700px) {
            font-size: 96px;

            &[data-lang='ru'] {
                font-size: 72px;
            }
        }
    }

    &__desc_pb30 {
        padding-bottom: 30px;
    }

    &__desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #505255;

        @media screen and (min-width: 744px) {
            font-size: 18px;
            line-height: 25px;
        }

        @media screen and (min-width: 1700px) {
            font-size: 24px;
            line-height: 33px;
        }
    }

    &__row {
        align-items: center;
    }

    &__col-left {
        padding-bottom: 30px;

        @media screen and (min-width: 1530px) {
            padding-right: 70px;
        }

    }

    &__col-right {
        @media screen and (min-width: 1530px) {
            padding-left: 70px;
        }

    }

    &-skills {
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: 2561px) {
            justify-content: flex-end;
        }

        &__item {
            width: calc(50% - 10px);
            margin-bottom: 30px;
            margin-right: 10px;
            min-height: 150px;
            min-width: 140px;

            &:nth-child(2n) {
                margin-right: 0px;
            }

            @media screen and (min-width: 1200px) {
                margin-right: 30px;
                max-width: 285px;
                width: 14.8vmax;
                height: 14vmax;
                max-height: 269px;
                min-height: 210px;
                min-width: 218px;
            }
        }
    }

    &__achievement {
        padding-top: 80px;

        @media screen and (min-width: 996px) {
            padding-top: 150px;
        }
    }

    &__achievement-preview {
        height: 225px;
        object-fit: cover;
        object-position: center;
        width: 100%;

        @media screen and (min-width: 744px) {
            height: 450px;
        }

        @media screen and (min-width: 996px) {
            height: 560px;
        }
    }

    &-achievements {
        margin-top: 18px;

        @media screen and (min-width: 744px) {
            margin-top: -100px;
        }

        @media screen and (min-width: 996px) {
            margin-top: -150px;
        }
    }

    &-achievements__paper {
        width: 40px;
        height: 40px;
        border-radius: 100%;


        @media screen and (min-width: 744px) {
            width: 180px;
            height: 180px;
            margin-bottom: 40px;
        }

        @media screen and (min-width: 996px) {
            width: 260px;
            height: 260px;

        }
    }

}