    $bg_active: #FF7E00;
    $bg_border_hover: #FF7E00;

    .custom-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .custom-checkbox+label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      margin-left: 2em;
      cursor: pointer;
      font-size: 20px;
      color: #646464;
      font-family: inherit;
      font-weight: 200;
      padding-left: 10px;
    }

    .custom-checkbox+label::before {
      content: '';
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 2px solid #999999;
      border-radius: 8px;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      position: absolute;
      left: 0px;
      top: 0px;
      transition: all 300ms ease;
    }

    .center.custom-checkbox+label::before {
      top: 50%;
      transform: translateY(-50%);
    }

    .custom-checkbox:focus+label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      transition: all 300ms ease;
    }

    .custom-checkbox:focus:not(:checked)+label::before {
      border-color: #80bdff;
      transition: all 300ms ease;
    }

    .custom-checkbox+label::after {
      content: "";
      transition: all 300ms ease;
      opacity: 0;
      position: absolute;
      left: 8px;
      top: 10px;
      width: 14px;
      height: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC0SURBVHgBlc6xDYJQEAbg+08sMNE4AiNYKD472ITaAGEDcQKRytYN3ABLtXIEN9DkYWPBmRdCgoUGr7rLfZf/QB3qniinX0lhev4DOzAHOpx6OlReB3zlF3wG9wqwFM/QXX3g5WzSxgO2fXt3urEQ1gYII22ODLYs1FjkYDCy48PszFukY5WCpE4QygQSgDCuSPaj/BK0k9E0ZbRICNWmmYVoO8zPCf2qMlJBGc9Fx276zbwB6ZNGxqcHDbUAAAAASUVORK5CYII=');
    }

    .center.custom-checkbox+label::after {
      content: "";
      top: 50%;
      transform: translateY(-50%);
    }

    .custom-checkbox:checked+label::after {
      content: "";
      transition: all 300ms ease;
      opacity: 1;
    }

    .custom-checkbox:disabled+label::before {
      background-color: #e9ecef;
      transition: all 300ms ease;
    }

    .checkbox {
      //margin-bottom: 1em;
      position: relative;
      cursor: pointer;
      height: fit-content;
    }