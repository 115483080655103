@import "./_variables.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  font-family: 'Manrope', serif;
}

footer,
header,
nav,
section,
main {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

svg path {
  transition: fill 300ms ease;
}

a:hover,
a,
button,
button:hover,
.link,
.link:hover {
  transition: all 300ms ease;
  text-decoration: none;
  border: none;
}

body {
  background: $background;
  color: $primary_text;
}

body::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: $background;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: $primary_text;
  /* color of the scroll thumb */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
button,
span,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.link {
  color: $black;
}

.link:hover {
  color: $link;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// GLOBAL
.title-primary {
  font-weight: 500;
  font-size: 80px;
}

.title-brand {
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: $primary_text;

  @media (min-width: 744px) {
    font-size: 44px;
  }

  @media (min-width: 1200px) {
    font-size: 64px;
  }
}

.title-secondary {
  font-size: 28px;
  font-weight: 700;

  @media screen and (min-width: 744px) {
    font-size: 36px;
  }

  @media screen and (min-width: 996px) {
    font-size: 38px;
  }

  @media screen and (min-width: 1520px) {
    font-size: 48px;
  }
}

.description-secondary {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: $desc;
  padding-bottom: 5px;

  @media screen and (min-width: 744px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1200) {
    font-size: 24px;
  }
}

.title-secondary_h1 {
  font-weight: 400;
  font-size: 64px;
}

.position_relative_overflow {
  position: relative;
  overflow: hidden;
}

.position_relative {
  position: relative;

  &-2k {
    @media (min-width: 2561px) {
      position: relative;
    }
  }
}

.marquee {
  &>.overlay {
    display: none;
  }
}

.flex-column-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.btn-brif_size-lang {
  max-width: 182px;
  width: 182px;
  min-height: 50px;

  &[data-lang='ru'] {
    span {
      font-size: 12.7px;
      font-weight: 600;
    }
  }

}

.btn-brif-big_size-lang {
  min-width: 232px;
  min-height: 53px;

  @media screen and (min-width: 744px) {
    min-width: 350px;
    max-width: 350px;
    min-height: 114px;
  }

  @media screen and (min-width: 1200px) {
    min-width: 300px;
    max-width: 300px;
    min-height: 88px;
  }

  @media screen and (min-width: 1520px) {
    min-width: 350px;
    max-width: 350px;
    min-height: 114px;
  }

}

.checkbox-agree_size[data-lang='ru'] {
  label {
    font-size: 19px;
  }
}

.active-line-bottom {
  width: 100% !important;
  transition: width 600ms ease;
}

@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
  animation: rotating 6s linear infinite;
}

// END GLOBAL
.container-2k{
  max-width: 1436px;
}
.container-more-2k {
  @media (min-width: 2561px) {
    max-width: 1920px;
    margin: 0 auto;
  }
}

.container-full-hd {
  @media (min-width: 1925px) {
    max-width: 1920px;
    margin: 0 auto;
  }
}

.container-off-2k.container {
  @media (min-width: 2561px) {
    max-width: 1436px;
  }
}

@import "./grid-bootstrap-5/index.scss";