.video {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &__overlay {
        transition: opacity 300ms ease, visible 300ms ease, z-index 300ms ease;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__play {
        width: 31px;
        color: #fff;
        height: 31px;
    }

    &_pause {
        opacity: 1;
        visibility: visible;
        z-index: 10;
    }

    &_play {
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
}