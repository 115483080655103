@import "../../styles/_variables.scss";

.title {
    color: $secondary_text;
    padding-top: 20px;
    padding-bottom: 40px;
}

.titleH1 {
    font-weight: 800;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on;
    color: $primary_text;
    padding-bottom: 28px;
    padding-top: 6px;
    font-size: 40px;
    line-height: 100%;

    @media screen and (min-width: 744px) {
        font-size: 80px;
    }

    @media screen and (min-width: 996px) {
        @include adaptive-var("font-size", 65, 20, 1);
    }

    @media screen and (min-width: 1530px) {
        @include adaptive-var("font-size", 96, 32, 1);
    }


}