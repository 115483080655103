.grid{
    display: grid;
    align-items: center;
    overflow: hidden;
}

.preview{
    width: 100%;
    height: 100%;
}

.list{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: clamp(20px,4.63vw,89px);
    li{
        width: 100%;
    }
    h4{
        color: #383838;
        font-variant-numeric: lining-nums proportional-nums ordinal;
        font-size: 28px;
        font-weight: 700;
        line-height: 90%;
        border-bottom: 1px solid #FFA041;
        padding-bottom: 10px;
        margin-bottom: 10px;
        @media (min-width: 1530px) {
            font-size: 46px;
        } 
    }
    p{
        color: #505255;
        font-size: 16px;
        font-weight: 400;
        @media (min-width: 1530px) {
            font-size: 24px;
        }
    }
}
.imgWrap{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    align-items: center;
}