@import "../../../styles/_variables.scss";

.case {
    padding-top: 15px;
    padding-bottom: 50px;
    overflow: hidden;

    &Section {
        position: relative;
        overflow: hidden;
    }
}

.slideItem {
    height: 36.45vmax;
    min-height: 360px;

    @media screen and (min-width: 744px) {
        min-height: 402px;
    }

    @media screen and (min-width: 1200px) {
        height: 48.45vmax;
    }
    @media screen and (min-width: 1530px) {
        height: 36.45vmax;
    }
}