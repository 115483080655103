@import "../../styles/variables";

.switcherLang {
    color: $primary_text;
    position: relative;

    @media screen and (min-width: 996px) {
        &:hover {
            .switcherLangBtn {
                color: $write;
                transition: color 300ms ease;

                &::before {
                    transform: scaleX(1);
                    opacity: 1;
                    transition: transform 400ms ease, opacity 400ms ease;
                }
            }

            .switcherLangBtnCustom {
                color: #999999;
                transition: color 300ms ease;

                &::before {
                    content: "";
                    display: none;
                }
            }

            .switcherLangList {
                opacity: 1;
                z-index: 1;
                visibility: visible;
                transition: all 400ms ease;
            }

            .switcherLangIcon {
                transform: rotateX(180deg);
                transition: transform 400ms ease;
            }
        }
    }
}

.switcherLangBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
    cursor: pointer;
    position: relative;
    transition: color 300ms ease;
    padding-top: 2px;
    padding-bottom: 2px;
    min-width: 58px;
    border-radius: 5px;

    &::before {
        content: "";
        z-index: -1;
        transform: scaleX(0);
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: $secondary_text;
        transition: transform 400ms ease, opacity 400ms ease;
        border-radius: 5px;
    }
}

.switcherLangBtnCustom {
    color: $write;
    padding-left: 3px;
    padding-right: 3px;
    min-width: 62px;

    &::before {
        content: "";
        display: none;
    }
}


.switcherLangTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-transform: uppercase;
    @media (min-width: 1200px) {
        font-size: 16px;
    }
    @media (min-width: 1520px) {
        font-size: 18px;
    }
}

.switcherLangIcon {
    width: 20px;
    height: 20px;
    transform: rotateX(0deg);
    transition: transform 400ms ease;

    &Open {
        transform: rotateX(180deg);
        transition: transform 400ms ease;
    }
}

.switcherLangList {
    background-color: $write;
    padding: 10px 15px;
    min-width: 82px;
    border-radius: 5px;
    opacity: 0;
    transition: all 400ms ease;
    visibility: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    &Item {
        padding-bottom: 10px;

        &:last-child {
            padding-bottom: 0px;
        }
    }

    &Open {
        opacity: 1;
        visibility: visible;
        transition: all 400ms ease;
    }

    &ItemTitle {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        text-transform: uppercase;
        padding-left: 4px;
        transition: color 400ms ease;
        cursor: pointer;
    }

    &ItemIcon {
        width: 22px;
        height: 13px;
    }

    &ItemSelected {
        color: $secondary_text;
    }

    @media screen and (min-width: 996px) {
        position: absolute;
        top: 36px;
        width: 100%;
        z-index: -1;
        visibility: hidden;
    }
}