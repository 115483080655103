.terabit {
    display: flex;
    align-items: center;
    margin-top: 16px;
    flex-direction: column;

    @media (min-width: 744px) {
        align-items: flex-start;
    }

    &__logo {
        width: 90px;
        height: 25px;
        color: rgb(56, 56, 56);
        display: flex;
        transition: all 300ms ease;

        @media (min-width: 576px) {
            width: 150px;
            height: 38px;
        }
    }

    &__title {
        color: rgb(56, 56, 56);
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: .25px;
        padding-bottom: 10px;
    }

    &__link {
        &:hover {
            transition: all 300ms ease;

            .terabit__logo {
                transition: all 300ms ease;
                color: rgba(56, 56, 56, 0.8);
            }
        }
    }
}