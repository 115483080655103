.container {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.icon {
    width: 150px;
    height: 150px;
    color: rgba(255, 160, 65, 1);

    @media (min-width: 744px) {
        width: 350px;
        height: 350px;
    }

    @media (min-width: 996px) {
        width: 650px;
        height: 650px;
    }

}