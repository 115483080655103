@import "../../../styles/_variables.scss";

.card {
    position: relative;
    height: 100%;
    min-height: 360px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    @media (min-width: 744px) {
        min-height: 402px;
    }

    @media (min-width: 1200px) {
        min-height: 340px;
    }

    @media (min-width: 1520px) {
        min-height: 402px;
    }


}

.hover:hover {
    .decor {
        @media screen and (min-width: 996px) {
            transform: scale(1);
            transition: transform 600ms ease-in-out;
        }
    }

    .preview {

        @media screen and (min-width: 996px) {
            filter: grayscale(0);
            transition: filter 800ms ease;
        }
    }
}

.active {
    .decor {
        transform: scale(1);
        transition: transform 600ms ease-in-out;
    }

    .preview {
        filter: grayscale(0);
        transition: filter 800ms ease;
        -webkit-backface-visibility: hidden;
        -webkit-filter: grayscale(0);
        -webkit-transition: filter 800ms ease;

    }

    .hoverOff {
        opacity: 1;
        transition: opacity 300ms ease;
        -webkit-transition: opacity 300ms ease;
    }

}

.decor {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    transform: scale(0);
    transform-origin: left top;
    transition: transform 600ms ease-in-out;
    width: 70%;

    @media screen and (min-width: 744px) {
        width: 70%;
    }
}

.decorActive {
    transform: scale(1);

    @media screen and (min-width: 996px) {
        transform: scale(0);
    }
}

.decorFull {
    @media screen and (min-width: 1200px) {
        width: 700px;
    }
}

.info {
    position: absolute;
    bottom: 34px;
    left: 10px;
    height: calc(100% - 34px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-top: 6px;
    cursor: pointer;
    max-width: 320px;
    text-align: left;
    width: 100%;
    padding-right: 10px;
    z-index: 5;

    @media screen and (min-width: 744px) {
        left: 55px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 252px;
    }

    @media screen and (min-width: 1530px) {
        max-width: 320px;
    }



}

.hoverOff {
    opacity: 0;
    transition: opacity 300ms ease;
    -webkit-transition: opacity 300ms ease;
}

.infoFull {
    @media screen and (min-width: 744px) {
        max-width: 588px;
    }

    @media screen and (min-width: 996px) {
        @include adaptive-var("left", 120, 50, 1);
    }
}

.preview {
    width: 100%;
    height: 100%;
    transition: filter 800ms ease;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 996px) {
        filter: grayscale(1);
        -webkit-backface-visibility: hidden;
        -webkit-filter: grayscale(1);
        -webkit-transition: filter 800ms ease;
        position: relative;
        z-index: 2;
    }

    @media screen and (min-width: 1200px) {
        background-size: 100% 100%;
    }

    @media screen and (min-width: 1530px) {
        background-size: cover;
    }
}

.previewFull {
    @media screen and (min-width: 1200px) {
        background-size: cover;
    }
}

.title {
    font-weight: 800;
    font-size: 20px;
    color: $write;
    line-height: 1;
    padding-bottom: 30px;
    word-wrap: break-word;
    max-width: 200px;

    @media screen and (min-width: 744px) {
        font-size: 48px;
        max-width: none;
    }

    @media screen and (min-width: 1200px) {
        font-size: 36px;
    }

    @media screen and (min-width: 1530px) {
        font-size: 48px;
    }
}

.titleFull {
    @media screen and (min-width: 744px) {
        @include adaptive-var("font-size", 77, 16, 1);
        padding-right: 94px;
    }
}

.desc {
    font-weight: 400;
    font-size: 10px;
    padding: 10px 12.5px;
    color: rgb(79, 82, 85);
    background: $write;
    display: inline-block;
    margin-right: 10px;
    width: fit-content;
    border-radius: 10px;

    @media screen and (min-width: 744px) {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
}

.descFull {
    @media screen and (min-width: 996px) {
        font-size: 24px;
        @include adaptive-var("font-size", 24, 16, 1);
    }
}