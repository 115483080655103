.grid-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    @media screen and (min-width: 996px) {
        padding-top: 70px;
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        padding-bottom: 30px;
    }

    &__info {
        font-weight: 400;
        font-size: 16px;
        padding-top: 20px;

        &.grid-footer_center {
            text-align: center;
        }

        a {
            font-weight: 500;
            font-size: 18px;
            color: #505255;
        }

        span {
            display: block;
        }
    }

    &__brief {
        width: 179px;
        height: 53px;

        span {
            font-weight: 500;
            font-size: 18px;
        }

        &[data-lang='ru'] {
            span {
                font-size: 18px;
                font-weight: 600;
            }
        }

        @media (min-width: 1200px) {
            min-width: 300px;
            max-width: 300px;
            min-height: 88px;

            span {
                font-size: 22px;
            }

            &[data-lang='ru'] {
                span {
                    font-size: 22px;
                }
            }
        }
    }
}