.cookies-bar {
    position: fixed;
    bottom: 29px;
    transform: translateX(-50%);
    left: calc(50% - 15px);
    padding: 20px;
    z-index: 1000;
    min-width: 280px;
    min-height: 30px;
    max-width: 480px;
    width: auto;
    border-radius: 10px;
    font-size: 14px;
    transition: all 300ms ease;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(56, 56, 56, 0.1);
    border-radius: 10px;
    width: calc(100% - 30px);
    margin: 15px;

    @media (min-width: 996px) {
        width: 466px;
        transform: translateX(0%);
        left: 29px;
    }

    &-enter {
        opacity: 0;
        transform: scale(0.9) translateX(-50%);

        @media (min-width: 996px) {
            transform: scale(0.9) translateX(0%);
        }
    }

    &-enter-active {
        opacity: 1;
        transform: scale(1) translateX(-50%);
        transition: opacity 300ms, transform 300ms;

        @media (min-width: 996px) {
            transform: scale(1) translateX(0%);
        }
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transform: scale(0.9) translateX(-50%);
        transition: opacity 300ms ease, transform 300ms ease;

        @media (min-width: 996px) {
            transform: scale(0.9) translateX(0%);
        }


    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: 540px) {
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &__msg {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 12px;
        text-align: center;

        @media (min-width: 540px) {
            width: calc(100% - 20px);
            max-width: 256px;
            padding-right: 20px;
            padding-bottom: 0px;
            text-align: left;
        }

        a {
            color: #383838;
            text-decoration: underline;
        }
    }

    &__btn {
        width: 153px;
    }
}